import React from 'react';
import { useQuery } from 'react-query';
import { fetchAppVersion } from '@tymely/api';
import { datadogLogs } from '@datadog/browser-logs';
import { useRecoilValue, useSetRecoilState, atom } from 'recoil';
import { IIdleTimer, useIdleTimer } from 'react-idle-timer';
import { DOCUMENT_TITLE, getEnvValue, IDLE_TIMER_TIMEOUT } from '@tymely/config';
import { getSeconds } from '@tymely/utils';

import { useSelectedComment } from './comment.services';
import { useTicket } from './ticket.services';

export const handlingTimerAtom = atom<IIdleTimer>({
    key: 'handlingTimer',
    default: undefined,
});

export const useHandlingTimer = () => useRecoilValue(handlingTimerAtom);

export const useTimeHandler = () => {
    const newHandlingTimer = useIdleTimer({ timeout: IDLE_TIMER_TIMEOUT, startOnMount: true });
    const handlingTimer = useHandlingTimer() || newHandlingTimer;
    const setHandlingTimer = useSetRecoilState(handlingTimerAtom);
    React.useEffect(() => {
        setHandlingTimer(handlingTimer);
    }, []);
    return handlingTimer;
};

export const useLogTicketProcess = () => {
    const ticket = useTicket();
    const comment = useSelectedComment();
    const handlingTimer = useTimeHandler();

    React.useEffect(() => {
        datadogLogs.setGlobalContextProperty('ticket_id', ticket.id);
    }, [ticket.id]);

    const logProcessed = (action: string) => {
        datadogLogs.logger.info('Ticket has been processed', {
            action: action,
            ticket_id: ticket.id,
            organization_id: ticket.organization_id,
            handling_duration: getSeconds(handlingTimer.getElapsedTime()),
            idle_duration: getSeconds(handlingTimer.getIdleTime()),
            active_duration: getSeconds(handlingTimer.getActiveTime()),
        });
    };

    return {
        logFailed: (wfId: number, actions: string[]) => {
            datadogLogs.logger.info('Reviewer marked ticket verification failed', {
                ticket_id: ticket.id,
                organization_id: ticket?.organization_id,
                exception: `Failed actions: ${actions.join(', ')}`,
                policy_set_id: comment?.selected_intent_id,
                workflow_id: wfId,
            });
        },
        logProcessed,
        logSentToQueue: (queueId: number, queueName: string) => {
            datadogLogs.logger.info(`Ticket sent to ${queueName} queue`, {
                ticket_id: ticket.id,
                organization_id: queueId,
            });
        },
    };
};

export const useAppVersionQuery = () => {
    return useQuery(['appVersion'], () => fetchAppVersion(), {
        placeholderData: { dinesh_version: '' },
        refetchInterval: 30 * 60 * 1000, // thirty minutes
        refetchIntervalInBackground: true,
    });
};

export const isMonitoringEnabled = () => {
    return getEnvValue('NX_DATADOG_APPLICATION_ID');
};

export const useDocumentTitle = (title: string, deps?: React.DependencyList) => {
    React.useEffect(() => {
        document.title = `${title} | ${DOCUMENT_TITLE}`;
        return () => {
            document.title = DOCUMENT_TITLE;
        };
    }, deps ?? []);
};
