import axios from 'axios';
import { IWfPolicy, IWorkflow, IUiPolicy } from '@tymely/atoms';

export type FetchWfIntentPolicyArgs = {
    orgPolicySetId: number;
    intentId: number;
    asSeenAt?: string;
};

export const fetchWfIntentPolicy = async ({ orgPolicySetId, intentId, asSeenAt }: FetchWfIntentPolicyArgs) => {
    const params: Record<string, unknown> = {
        org_policy_set_id: orgPolicySetId,
        policy_set_id: intentId,
        as_seen_at: asSeenAt,
    };
    return await axios.get<IWfPolicy>('wf-policy/search', { params }).then((res) => res.data);
};

export const upsertWfIntentPolicy = async (policy: IUiPolicy, mode?: 'overwrite' | 'append') =>
    await axios
        .put<IWfPolicy>('wf-policy', policy, {
            params: { org_policy_set_id: policy.orgId, policy_set_id: policy.intentId, mode },
        })
        .then((res) => ({ ...policy, ...res.data }));

export const invalidateWfIntentPolicyCache = async (policy: Pick<IUiPolicy, 'orgId' | 'intentId'>) =>
    await axios
        .put<IWfPolicy>(
            'wf-policy/invalidate-cache',
            {},
            {
                params: { org_policy_set_id: policy.orgId, policy_set_id: policy.intentId },
            },
        )
        .then((res) => ({ ...policy, ...res.data }));

export const changeWFStatus = async (id: IWorkflow['id'], status: IWorkflow['status']) =>
    await axios.put<IWorkflow>(`workflow/${id}/status`, { status }).then((res) => res.data);
