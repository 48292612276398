import uniq from 'lodash/uniq';
import omit from 'lodash/omit';
import {
    FocusEventHandler,
    memo,
    FormEvent,
    useState,
    forwardRef,
    useEffect,
    useMemo,
    Suspense,
    useCallback,
    ChangeEvent,
    SyntheticEvent,
    ReactNode,
    useLayoutEffect,
} from 'react';
import {
    Autocomplete,
    Box,
    FormControlLabel,
    MenuItem,
    Switch,
    TextField,
    FormGroup,
    InputBaseComponentProps,
    useTheme,
    TextFieldProps,
    styled,
    Typography,
    Button,
    Tabs,
    Tab,
    CircularProgress,
} from '@mui/material';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { TypeOf, z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { js_beautify } from 'js-beautify';
import { IArgumentMetadata, ArgTypes, ArgScopes, isCategoricalMetadata } from '@tymely/atoms';
import {
    useArgExtractorsQuery,
    useArgumentSchemaQuery,
    useArgumentsMetadataQuery,
    useCreateArgumentMetadataMutation,
    useDTypeHintsQuery,
    useLlmExperimentSchemaQuery,
} from '@tymely/services';
import { BaseModal, IconButton, IconButtonProps } from '@tymely/components';
import ResizeIcon from '@tymely/components/Icons/Resize';
import { CodeEditor } from '@tymely/components/CodeEditor';

import { PromptExperimentModal } from '../PromptExperimentModal/PromptExperimentModal';
import { TemplateEditor } from '../TemplateEditor';

export const defaultArgMetadata: IArgumentMetadata = {
    id: -1,
    title: '',
    description: '',
    extractor_name: 'user_input',
    dtype: 'str',
    arg_type: 'USER_INPUT',
    scope: 'COMMENT',
    is_list: false,
    is_ticket_arg: false,
    unspecifiable: true,
    dependencies: [],
    name: '',
    params: {},
    additional_data: {},
    created_date: new Date(),
};

const jsonValidator = () =>
    z.nullable(z.string()).refine(
        (str) => {
            try {
                return str ? JSON.parse(str) : true;
            } catch (_error) {
                return false;
            }
        },
        { message: 'Invalid JSON' },
    );

const formSchema = z.object({
    name: z.string().min(1, { message: 'Name is required' }).regex(/^\w+$/, { message: 'Name must be alphanumeric' }),
    title: z.string().min(1, { message: 'Title is required' }),
    description: z.string().min(1, { message: 'Description is required' }),
    dtype: z.string().min(1, { message: 'DType is required' }),
    arg_type: z.enum(ArgTypes, { required_error: 'Argument Type is required' }),
    scope: z.enum(ArgScopes, { required_error: 'Scope is required' }),
    extractor_name: z.string().min(1, { message: 'Extractor is required' }),
    options: z
        .nullable(
            z.object({
                categories: jsonValidator().nullable(),
                dynamic_categories: jsonValidator().nullable(),
                neitherable: z.boolean().nullable(),
                group_by_label: z.boolean().nullable(),
            }),
        )
        .optional(),
    unspecifiable: z.boolean(),
    params: jsonValidator(),
    additional_data: z
        .object({
            ttl_minutes: z.union([
                z.number().min(0).optional(),
                z
                    .string()
                    .transform((val) => (val ? Number(val) : undefined))
                    .refine((num) => num === undefined || Number(num) >= 0, 'Expected non-negative number')
                    .optional(),
            ]),
            cache_missing_value: z.boolean().optional(),
            show_description: z.boolean().optional(),
        })
        .optional(),
});

type FormInput = TypeOf<typeof formSchema>;

const JsonEditor = forwardRef<HTMLInputElement, InputBaseComponentProps & { name: string; disabled?: boolean }>(
    (props, ref) => {
        const theme = useTheme();
        const { onChange } = props;
        const editor = useMemo(() => ({ value: '', nodeName: 'INPUT' }) as HTMLInputElement, []);

        useEffect(() => {
            if (typeof ref === 'function') {
                ref(editor);
            }
        }, []);

        return (
            <Box width="100%" mb={1}>
                <Suspense>
                    <CodeEditor
                        code={editor.value}
                        language="json"
                        onChange={
                            props.disabled
                                ? undefined
                                : (value: string) => {
                                      onChange?.({
                                          target: { name: props.name, value },
                                      } as unknown as FormEvent<HTMLInputElement>);
                                  }
                        }
                        editorProps={{
                            maxHeight: theme.spacing(22),
                            onBlur: props.onBlur as unknown as FocusEventHandler<HTMLInputElement>,
                        }}
                    />
                </Suspense>
            </Box>
        );
    },
);

JsonEditor.displayName = 'JsonEditor';

const ExpandButton = styled((props: IconButtonProps) => (
    <IconButton {...props}>
        <ResizeIcon style={{ transform: 'rotate(45deg)' }} />
    </IconButton>
))(
    ({ theme }) => `
    width: ${theme.spacing(3)};
    height: ${theme.spacing(3)};
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid var(--ty-palette-divider);
`,
);

const CodeEditorModal = memo(
    (props: {
        open: boolean;
        name?: string;
        title: string;
        code: string;
        loading?: boolean;
        jinjaField?: string;
        onClose: () => void;
        onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    }) => {
        const [tab, setTab] = useState(0);
        const [jsonCode, setJsonCode] = useState('');
        const [template, setTemplate] = useState<string>();

        const json = useMemo(() => {
            try {
                return JSON.parse(props.code) as Record<string, unknown>;
            } catch {
                return null;
            }
        }, [props.code]);

        useEffect(() => {
            setJsonCode(js_beautify(props.code));
        }, [props.code]);

        useEffect(() => {
            if (props.jinjaField && typeof json?.[props.jinjaField] === 'string') {
                setTemplate(json[props.jinjaField] as string);
            }
        }, [json, props.jinjaField]);

        const onSave = useCallback(() => {
            props.onChange?.({
                target: { name: props.name, value: jsonCode },
            } as unknown as ChangeEvent<HTMLInputElement>);
            props.onClose();
        }, [props.name, jsonCode, template, props.onClose]);

        const onTab = useCallback((_: SyntheticEvent, index: number) => {
            setTab(index);
        }, []);

        const onJinjaChange = useCallback(
            (code: string) => {
                const json = JSON.parse(jsonCode) as Record<string, unknown>;
                json[props.jinjaField!] = code;
                setJsonCode(js_beautify(JSON.stringify(json)));
                setTemplate(code);
            },
            [jsonCode, props.jinjaField],
        );

        return (
            <BaseModal open={props.open} maxWidth="xl" fullHeight onOk={onSave} onClose={props.onClose}>
                <Box display="flex" flexDirection="column" height={1} pb={0.5}>
                    <Tabs value={tab} onChange={onTab}>
                        <Tab label={props.title}></Tab>
                        {typeof template === 'string' && <Tab label="Value"></Tab>}
                    </Tabs>
                    {props.loading ? (
                        <CodeEditor code="loading..." language="jinja2" />
                    ) : (
                        <>
                            {tab === 0 && (
                                <Suspense>
                                    <CodeEditor code={jsonCode} language="json" onChange={setJsonCode} />
                                </Suspense>
                            )}
                            {tab === 1 && (
                                <Box flex={1}>
                                    <TemplateEditor template={template} onChange={onJinjaChange} />
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            </BaseModal>
        );
    },
);

CodeEditorModal.displayName = 'CodeEditorModal';

const JsonTextField = memo(
    (
        props: TextFieldProps & {
            jinjaField?: string;
            labelItems?: ReactNode;
        },
    ) => {
        const [open, setOpen] = useState(false);
        const [input, setInput] = useState<HTMLInputElement>();

        const onChange = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                input!.value = e.target.value;
                props.inputProps?.onChange?.(e);
            },
            [input, props.inputProps],
        );

        const { jinjaField, labelItems, ...rest } = props;

        return (
            <Box position="relative">
                <TextField
                    {...rest}
                    margin="dense"
                    fullWidth
                    variant="standard"
                    inputProps={{
                        ...props.inputProps,
                        ref: (input: HTMLInputElement) => {
                            props.inputProps?.ref(input);
                            setInput(input);
                        },
                        onChange,
                    }}
                    label={
                        <Box display="flex">
                            <Typography mr={1}>{props.label}</Typography>
                            {props.labelItems}
                            {/*{(props.labelButtons || []).map(labelButton => (*/}
                            {/*    <Button disableRipple onClick={labelButton.onClick} sx={{ p: 0 }}>*/}
                            {/*        {labelButton.label}*/}
                            {/*    </Button>*/}
                            {/*))}*/}
                        </Box>
                    }
                    InputProps={{
                        inputComponent: JsonEditor,
                        disabled: props.disabled,
                    }}
                />
                <ExpandButton onClick={() => setOpen(true)} />
                <CodeEditorModal
                    open={open}
                    code={input?.value || ''}
                    jinjaField={props.jinjaField}
                    name={props.inputProps?.name}
                    title={props.label as string}
                    onClose={() => setOpen(false)}
                    onChange={onChange}
                />
            </Box>
        );
    },
);

JsonTextField.displayName = 'JsonTextField';

const SchemaModal = (props: { open: boolean; onClose: () => void; dtype?: string }) => {
    const { data: schemaObj, isLoading } = useArgumentSchemaQuery(props.dtype);
    const schema = useMemo(() => (schemaObj ? js_beautify(JSON.stringify(schemaObj)) : null), [schemaObj]);

    return (
        <CodeEditorModal
            open={props.open}
            title="Params Schema"
            loading={isLoading}
            code={schema || ''}
            onClose={props.onClose}
        />
    );
};

const defaultDynamicCategories = {
    base_arg_md_id: 0,
    jsonpath: '',
    label: '',
    group_by_arg_md_id: null,
};

export function ArgMetadataEditDialog(props: {
    argMetadata: Partial<IArgumentMetadata>;
    onSubmit: (arg: IArgumentMetadata) => Promise<unknown>;
    onClose: () => void;
}) {
    const { data: extractors } = useArgExtractorsQuery();
    const argsMetadataQuery = useArgumentsMetadataQuery();
    const argNames = useMemo(
        () => Object.fromEntries(argsMetadataQuery.data?.map((arg) => [arg.name, arg.id]) || []),
        [argsMetadataQuery.data],
    );
    const extractorNames = useMemo(() => extractors?.map((e) => e.name), [extractors]);
    const extractor = extractors?.find((e) => e.name === props.argMetadata.extractor_name);

    const argDTypeHints = useDTypeHintsQuery();
    const [dialogValue, setDialogValue] = useState(props.argMetadata);
    const [saving, setSaving] = useState(false);
    const [showDynCat, setShowDynCat] = useState(!!props.argMetadata.options?.dynamic_categories);
    const [schemaOpen, setSchemaOpen] = useState(false);

    const {
        register,
        formState: { errors, isValid, defaultValues },
        setValue,
        getValues,
        handleSubmit,
        control,
        getFieldState,
        setError,
    } = useForm<FormInput>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            ...props.argMetadata,
            options: props.argMetadata.options
                ? {
                      categories: js_beautify(JSON.stringify(props.argMetadata.options.categories || {})),
                      dynamic_categories: js_beautify(
                          JSON.stringify(props.argMetadata.options.dynamic_categories || defaultDynamicCategories),
                      ),
                      neitherable: props.argMetadata.options.neitherable,
                      group_by_label: props.argMetadata.options.group_by_label,
                  }
                : {
                      categories: '{}',
                      dynamic_categories: js_beautify(JSON.stringify(defaultDynamicCategories)),
                      neitherable: false,
                      group_by_label: false,
                  },
            params: props.argMetadata.params && js_beautify(JSON.stringify(props.argMetadata.params)),
            dtype: props.argMetadata.dtype ?? 'str',
            arg_type: props.argMetadata.arg_type ?? ArgTypes[0],
            scope: props.argMetadata.scope ?? ArgScopes[0],
        },
    });

    const { data: llmExperimentSchema } = useLlmExperimentSchemaQuery();

    const [isCategoriesTouched, setIsCategoriesTouched] = useState(!!props.argMetadata.options?.categories);

    const dtypePrimitive = useMemo(() => {
        const dtype = getValues('dtype');
        const match = dtype.match(/list\[(.+)\]/);
        return match ? match[1] : dtype;
    }, [getValues('dtype')]);

    const { data: dtypeSchema, isLoading: dtypeSchemaIsLoading } = useArgumentSchemaQuery(dtypePrimitive);

    useLayoutEffect(() => {
        if (!isCategoriesTouched) {
            setValue(
                'options.categories',
                dtypeSchema?.enum
                    ? JSON.stringify(Object.fromEntries(dtypeSchema.enum.map((key) => [key, key])), null, 2)
                    : '{}',
                { shouldValidate: true },
            );
        }
    }, [dtypeSchema, isCategoriesTouched]);

    const onSubmitHandler: SubmitHandler<FormInput> = (values: FormInput) => {
        if (!isValid) return;
        if (values.name in argNames && (!props.argMetadata.id || argNames[values.name] !== props.argMetadata.id)) {
            setError('name', { type: 'unique', message: 'Name must be unique' });
            return;
        }
        const parsed = {
            params: values.params ? JSON.parse(values.params) : dialogValue.params,
            options: values.options
                ? {
                      ...dialogValue.options,
                      categories: showDynCat
                          ? null
                          : values.options.categories
                            ? JSON.parse(values.options.categories)
                            : {},
                      dynamic_categories: showDynCat
                          ? values.options?.dynamic_categories
                              ? JSON.parse(values.options?.dynamic_categories)
                              : {}
                          : null,
                      neitherable: values.options.neitherable,
                      group_by_label: values.options.group_by_label,
                  }
                : dialogValue.options,
            additional_data: {
                ...dialogValue.additional_data,
                ...values.additional_data,
                cache_missing_value: getFieldState('additional_data.cache_missing_value').isTouched
                    ? values.additional_data?.cache_missing_value
                    : dialogValue.additional_data?.cache_missing_value,
                ...(values.additional_data?.show_description
                    ? { show_description: values.additional_data?.show_description }
                    : {}),
            },
        };
        setSaving(true);
        props.onSubmit({ ...dialogValue, ...values, ...parsed } as IArgumentMetadata).finally(() => setSaving(false));
    };

    const [experimentArgMd, setExperimentArgMd] = useState<IArgumentMetadata>();
    const createArgumentMetadata = useCreateArgumentMetadataMutation();
    const [isOpeningExperimentModal, setIsOpeningExperimentModal] = useState(false);
    const openPromptExperimentModal = async () => {
        try {
            setIsOpeningExperimentModal(true);
            const argMdClone = await createArgumentMetadata.mutateAsync({
                metadata: {
                    ...omit(props.argMetadata, ['id']),
                    deleted_at: new Date(),
                } as IArgumentMetadata,
            });
            setExperimentArgMd(argMdClone);
        } finally {
            setIsOpeningExperimentModal(false);
        }
    };

    const footer = useMemo(
        () => (
            <Box>
                {props.argMetadata.id && (
                    <Typography display="inline-block" variant="h6" fontWeight="bold">
                        {props.argMetadata.id?.toString()}
                    </Typography>
                )}
                {props.argMetadata.deleted_at && (
                    <Typography display="inline-block" variant="h6" fontWeight="bold" color="red">
                        Deleted
                    </Typography>
                )}
            </Box>
        ),
        [props.argMetadata],
    );

    const canRunExperiment = useMemo(() => {
        const md = props.argMetadata;
        return ['USER_INPUT', 'TEXT_ARGUMENT'].includes(md.arg_type!) && md.extractor_name === 'llm';
    }, [props.argMetadata]);

    return (
        <BaseModal
            open={!!props.argMetadata}
            onClose={props.onClose}
            title={props.argMetadata.id ? 'Edit argument metadata' : 'Create a new argument'}
            loading={saving}
            onOk={handleSubmit(onSubmitHandler)}
            closable={!saving}
            footer={footer}
        >
            <form noValidate>
                <TextField
                    required
                    fullWidth
                    autoFocus
                    margin="dense"
                    label="name"
                    type="text"
                    variant="standard"
                    error={!!errors.name}
                    helperText={errors.name?.message || ''}
                    {...register('name')}
                />
                <TextField
                    required
                    fullWidth
                    autoFocus
                    margin="dense"
                    label="title"
                    type="text"
                    variant="standard"
                    error={!!errors.title}
                    helperText={errors.title?.message || ''}
                    {...register('title')}
                />
                <TextField
                    required
                    fullWidth
                    margin="dense"
                    label="description"
                    type="text*"
                    variant="standard"
                    error={!!errors.description}
                    helperText={errors.description?.message || ''}
                    sx={{ mb: 1 }}
                    InputProps={{
                        endAdornment: canRunExperiment && (
                            <Button
                                onClick={() => {
                                    openPromptExperimentModal();
                                }}
                                sx={{ whiteSpace: 'nowrap' }}
                                disabled={isOpeningExperimentModal}
                            >
                                <Box sx={{ display: 'flex', gap: 0.75, alignItems: 'center' }}>
                                    {isOpeningExperimentModal && (
                                        <CircularProgress size={16} color="inherit" sx={{ mt: '-2px' }} />
                                    )}
                                    Open in editor
                                </Box>
                            </Button>
                        ),
                    }}
                    {...register('description')}
                />
                <FormGroup row sx={{ mb: 1.5, d: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <FormControlLabel
                            label="Unspecifiable"
                            control={
                                <Switch defaultChecked={defaultValues?.unspecifiable} {...register('unspecifiable')} />
                            }
                        />
                        {isCategoricalMetadata({ options: getValues().options as IArgumentMetadata['options'] }) && (
                            <FormControlLabel
                                label="Neitherable"
                                control={
                                    <Switch
                                        defaultChecked={dialogValue.options?.neitherable}
                                        {...register('options.neitherable')}
                                    />
                                }
                            />
                        )}
                    </Box>
                    <FormControlLabel
                        label="Always show description"
                        control={
                            <Switch
                                defaultChecked={defaultValues?.additional_data?.show_description}
                                {...register('additional_data.show_description')}
                            />
                        }
                    />
                </FormGroup>
                <Controller
                    control={control}
                    name="extractor_name"
                    defaultValue={defaultValues?.extractor_name}
                    render={({ field: { onChange, ...props }, fieldState: { error } }) => (
                        <Autocomplete
                            size="small"
                            fullWidth
                            options={uniq(extractorNames ?? []).sort()}
                            onChange={(_, extractorName) => {
                                const typeBits = extractor?.return_type
                                    .split('|')
                                    .map((bit) => bit.trim())
                                    .filter((bit) => bit !== 'None');

                                setValue(
                                    'dtype',
                                    typeBits?.length && argDTypeHints.data?.find((dtype) => dtype === typeBits[0])
                                        ? typeBits[0]
                                        : 'str',
                                    { shouldValidate: true },
                                );

                                onChange(extractorName);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={Boolean(error)}
                                    helperText={error?.message || ''}
                                    label="Arg extractor*"
                                />
                            )}
                            loading={!extractorNames}
                            {...props}
                            value={props.value || null}
                            sx={{ mb: 1 }}
                        />
                    )}
                />
                <FormGroup row sx={{ mb: 1, justifyContent: 'space-between' }}>
                    <TextField
                        sx={{ width: '32%' }}
                        margin="dense"
                        size="small"
                        select
                        label="Argument Type"
                        required
                        defaultValue={props.argMetadata.arg_type}
                        inputProps={register('arg_type')}
                    >
                        {ArgTypes.map((argType) => (
                            <MenuItem value={argType} key={argType}>
                                {argType}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Controller
                        control={control}
                        name="dtype"
                        defaultValue={defaultValues?.dtype}
                        render={({ field: { onChange, ...props }, fieldState: { error } }) => (
                            <Autocomplete
                                size="small"
                                freeSolo
                                options={uniq(argDTypeHints.data ?? []).sort()}
                                onChange={(_, newValue) => {
                                    newValue && setDialogValue({ ...dialogValue, dtype: newValue });
                                    onChange(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={Boolean(error)}
                                        helperText={error?.message || ''}
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                        label={
                                            <Box display="flex">
                                                <Typography mr={1}>DType *</Typography>
                                                <Button sx={{ p: 0 }} disableRipple onClick={() => setSchemaOpen(true)}>
                                                    Schema
                                                </Button>
                                            </Box>
                                        }
                                    />
                                )}
                                loading={argDTypeHints.isLoading}
                                disabled={argDTypeHints.isLoading}
                                {...props}
                                value={props.value || null}
                                sx={{ mt: 1, width: '32%' }}
                            />
                        )}
                    />
                    <TextField
                        sx={{ width: '32%' }}
                        margin="dense"
                        size="small"
                        select
                        label="Scope"
                        required
                        defaultValue={props.argMetadata.scope}
                        inputProps={register('scope')}
                    >
                        {ArgScopes.map((scope) => (
                            <MenuItem value={scope} key={scope}>
                                {scope}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormGroup>
                <FormGroup row sx={{ mb: 1, alignItems: 'center' }}>
                    <TextField
                        label="Revoke after"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        margin="dense"
                        error={!!errors.additional_data?.ttl_minutes}
                        helperText={errors.additional_data?.ttl_minutes?.message}
                        type="number"
                        inputProps={register('additional_data.ttl_minutes')}
                        InputProps={{
                            placeholder: `Default (${extractor?.ttl_minutes ?? 'none'}${
                                extractor?.ttl_minutes ? 'm' : ''
                            })`,
                            endAdornment: 'mins',
                            sx: { input: { pr: 1 } },
                        }}
                        sx={{ width: '49%' }}
                        size="small"
                        FormHelperTextProps={{ sx: { ml: 0 } }}
                    />
                    <FormControlLabel
                        label="Cache empty result"
                        sx={{ width: '49%', m: 0 }}
                        control={
                            <Switch
                                defaultChecked={defaultValues?.additional_data?.cache_missing_value ?? true}
                                {...register('additional_data.cache_missing_value')}
                            />
                        }
                    />
                </FormGroup>
                {!showDynCat && (
                    <JsonTextField
                        label="Categories"
                        labelItems={
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                {!isCategoriesTouched && dtypeSchemaIsLoading ? (
                                    <CircularProgress size={16} />
                                ) : (
                                    <>
                                        <Button sx={{ p: 0 }} disableRipple onClick={() => setShowDynCat(!showDynCat)}>
                                            Dynamic
                                        </Button>
                                        {isCategoriesTouched && (
                                            <Button
                                                onClick={() => {
                                                    setIsCategoriesTouched(false);
                                                    setValue('options.categories', '{}');
                                                }}
                                                color="secondary"
                                                sx={{ p: 0 }}
                                            >
                                                Reset
                                            </Button>
                                        )}
                                    </>
                                )}
                            </Box>
                        }
                        inputProps={register('options.categories')}
                        onChange={() => setIsCategoriesTouched(true)}
                        disabled={dtypeSchemaIsLoading && !isCategoriesTouched}
                        helperText={errors.options?.categories?.message}
                        error={!!errors.options?.categories}
                    />
                )}
                {showDynCat && (
                    <JsonTextField
                        label="Dynamic categories"
                        labelItems={
                            <Button sx={{ p: 0 }} disableRipple onClick={() => setShowDynCat(!showDynCat)}>
                                Static
                            </Button>
                        }
                        inputProps={register('options.dynamic_categories')}
                        helperText={errors.options?.dynamic_categories?.message}
                        error={!!errors.options?.dynamic_categories}
                    />
                )}
                <JsonTextField
                    label="Params"
                    error={!!errors.params}
                    helperText={errors.params?.message}
                    inputProps={register('params')}
                    jinjaField="value"
                    sx={{ mb: 1 }}
                />
                {schemaOpen && (
                    <SchemaModal open={schemaOpen} onClose={() => setSchemaOpen(false)} dtype={dialogValue.dtype} />
                )}
                {experimentArgMd && llmExperimentSchema && (
                    <PromptExperimentModal
                        argMetadata={experimentArgMd}
                        llmExperimentSchema={llmExperimentSchema}
                        onClose={() => setExperimentArgMd(undefined)}
                        onSubmit={async (description) => {
                            setValue(
                                'params',
                                JSON.stringify(
                                    {
                                        ...(JSON.parse(getValues('params') || '{}') || {}),
                                        prompt_override: description,
                                    },
                                    null,
                                    4,
                                ),
                            );
                            setExperimentArgMd(undefined);
                        }}
                    />
                )}
            </form>
        </BaseModal>
    );
}
