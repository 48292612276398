import { useImperativeHandle, useEffect, useState, forwardRef, useCallback, ReactNode } from 'react';
import {
    IconButton,
    styled,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    DialogProps,
    Box,
    Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

export const SubmitButton = styled((props: LoadingButtonProps) => (
    <LoadingButton variant="contained" {...props} size="small" />
))(({ theme }) => ({
    textAlign: 'right',
    fontSize: 16,
    marginLeft: 'auto',
    '&:not(:first-of-type)': {
        marginLeft: theme.spacing(2),
    },
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
    padding: 0,
    position: 'absolute',
    right: 20,
    top: 20,
    color: theme.palette.grey[500],
}));

export type ModalApi = {
    open: () => void;
    close: () => void;
};

type BaseProps = DialogProps & {
    fullHeight?: boolean;
    onClose?: () => void;
    loading?: boolean;
    disabled?: boolean | string;
    closable?: boolean;
    okLabel?: string;
    closeLabel?: string | null;
    onOk?: () => void;
    footer?: ReactNode;
};

export const BaseModal = forwardRef<ModalApi, BaseProps>((props, ref) => {
    const [open, setOpen] = useState(props.open);
    const {
        loading,
        disabled,
        onOk,
        okLabel,
        closeLabel,
        children,
        fullHeight,
        title,
        closable = true,
        ...rest
    } = props;

    useImperativeHandle(ref, () => ({
        open: () => setOpen(true),
        close: () => setOpen(false),
    }));

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const onClose = useCallback(
        (_: object, reason: string) => {
            if ((reason === 'backdropClick' || reason === 'escapeKeyDown') && !closable) return;
            setOpen(false);
            props.onClose?.();
        },
        [closable, props.onClose],
    );

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            {...rest}
            onClose={onClose}
            open={open}
            PaperProps={{ sx: { height: props.fullHeight ? '100vh' : 'auto' } }}
        >
            <DialogTitle variant="h6" sx={{ position: 'relative', pb: 0 }}>
                {title}
                {closable && (
                    <CloseButton
                        disabled={props.loading}
                        aria-label="close"
                        tabIndex={-1}
                        onClick={() => onClose({}, 'buttonClick')}
                    >
                        <CloseIcon />
                    </CloseButton>
                )}
            </DialogTitle>
            <DialogContent sx={{ pb: 0, '&.MuiDialogContent-root': { pt: 2 } }}>{children}</DialogContent>
            <DialogActions sx={{ p: 3, pt: 2, pb: 2 }}>
                {props.footer && <Box flex="1 0 0">{props.footer}</Box>}
                {closable && closeLabel !== null && (
                    <Button disabled={loading} onClick={props.onClose}>
                        {closeLabel || 'Cancel'}
                    </Button>
                )}
                {onOk && (
                    <Tooltip title={typeof disabled === 'string' ? disabled : undefined}>
                        <span>
                            <SubmitButton loading={loading} disabled={Boolean(disabled)} onClick={onOk} sx={{ ml: 2 }}>
                                {okLabel || 'OK'}
                            </SubmitButton>
                        </span>
                    </Tooltip>
                )}
            </DialogActions>
        </Dialog>
    );
});

BaseModal.displayName = 'BaseModal';
