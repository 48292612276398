import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    Box,
    Card,
    CardMedia,
    Collapse,
    List,
    ListItem,
    Skeleton,
    Slide,
    styled,
    Typography,
    useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { chain } from 'lodash';
import MarkdownIt from 'markdown-it';
import { TransitionGroup } from 'react-transition-group';
import grey from '@mui/material/colors/grey';
import {
    ICrumbAdditionalData,
    IDineshTicketOperations,
    IInferIntent,
    IPolicySet,
    IPolicySetGroup,
    IWorkflowExplanation,
} from '@tymely/atoms';
import { useCreateTicketCrumb, useDemoTicket, useIntentsQuery, useSelectedComment } from '@tymely/services';
import {
    INTENTS_BOTTOM_CATEGORIES,
    INTENTS_DEFAULT_CATEGORY,
    INTENTS_DEPRECATED_CATEGORIES,
    INTENTS_TOP_CATEGORIES,
    INTENTS_TOP_INFERRED_CATEGORY,
    NEITHER_LOGIC_BOX_ID,
} from '@tymely/config';
import { sanitizeHtmlElements, useDebounce } from '@tymely/utils';

import { Loader } from '../Loader';
import { IntentsList } from './IntentsList';
import { SearchAddIntent } from './SearchAddIntent';
import { containsHebrew, switchHebrewToEnglish } from './QuertyMapping';
import { DetailsSection } from './DetailsSection';

const Root = styled(Box)({
    position: 'relative',
});

const StyledIntentTitle = styled(DetailsSection)(({ theme }) => ({
    height: '100%',
    '& .titles': {
        marginBottom: theme.spacing(1),
    },
}));

export const StyledLoader = styled(Loader)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(10),
    height: 'auto',
}));

const searchIntent = (intents: IPolicySet[], searchTerm: string) => {
    if (!searchTerm.trimStart()) {
        return intents;
    }

    const reg = new RegExp(`\\b${searchTerm.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&').trimStart()}\\w*`, 'gi');

    return intents.filter(
        (intent) =>
            intent.category === INTENTS_DEFAULT_CATEGORY ||
            intent.name.concat(intent.id.toString()).match(reg) ||
            intent.id.toString().match(reg),
    );
};

const addProposedIntents = (policySets: IPolicySet[], inferredIntents?: IInferIntent[]) => {
    return policySets.reduce<IPolicySet[]>((acc, policySet) => {
        acc.push(policySet);

        const inferIntent = inferredIntents?.find(
            ({ intent_id }) => intent_id === policySet.id && intent_id !== NEITHER_LOGIC_BOX_ID,
        );

        if (inferIntent) {
            acc.push({
                ...policySet,
                category: INTENTS_TOP_INFERRED_CATEGORY,
                weight: inferIntent.weight || 0,
            });
        }

        return acc;
    }, []);
};

const categoryOrderIndex = (category: string) => {
    let index = INTENTS_TOP_CATEGORIES.findIndex((c) => c.toLowerCase() === category);
    if (index !== -1) {
        return `@${category}`;
    }
    index = INTENTS_BOTTOM_CATEGORIES.findIndex((c) => c.toLowerCase() === category);
    if (index !== -1) {
        return `~${category}`;
    }
    return category;
};

const sortGroupedIntents = (groupedIntents: IPolicySetGroup[]) =>
    groupedIntents.sort(({ category: categoryA }, { category: categoryB }) => {
        const sortStringA = categoryOrderIndex(categoryA.toLowerCase());
        const sortStringB = categoryOrderIndex(categoryB.toLowerCase());

        if (sortStringA < sortStringB) return -1;
        if (sortStringA > sortStringB) return 1;
        return 0;
    });

function groupAndSortIntents(intents: IPolicySet[]): IPolicySetGroup[] {
    const groupedIntents = chain(intents || [])
        .groupBy((intent) => intent.category.toLowerCase())
        .map((intents, category) => ({
            category,
            intents: intents.sort(({ weight: w1 = 0 }, { weight: w2 = 0 }) => w2 - w1),
        }))
        .filter(({ category }) => !INTENTS_DEPRECATED_CATEGORIES.find((c) => c.toLowerCase() === category))
        .value();
    return sortGroupedIntents(groupedIntents);
}

export const Intents = memo(
    (props: {
        selectedIds: IPolicySet['id'][];
        disabled?: boolean;
        onIntentSelect?: (intent: IPolicySet, searchTerm?: string) => void;
    }) => {
        const selectedComment = useSelectedComment();
        const [search, setSearch] = useState('');
        const searchVal = useDebounce(search, 300) || '';
        const [loading, setLoading] = useState(false);
        const inferredIntents = selectedComment?.infer_data?.intents;
        const intents = useIntentsQuery();
        const createTicketCrumb = useCreateTicketCrumb();
        const theme = useTheme();

        const allIntentGroups = useMemo(
            () => groupAndSortIntents(addProposedIntents(intents.data ?? [], inferredIntents)),
            [intents.data, inferredIntents],
        );

        const intentGroups = useMemo(() => {
            if (!searchVal.length) {
                return allIntentGroups;
            }

            if (searchVal.length <= 1) {
                return [];
            }

            const additionalData: ICrumbAdditionalData = { search_term: searchVal, comment_id: selectedComment?.id };
            createTicketCrumb(IDineshTicketOperations.USER_SEARCHED_INTENT, additionalData);

            const searchTerm = containsHebrew(searchVal) ? switchHebrewToEnglish(searchVal) : searchVal;
            const foundIntents = addProposedIntents(searchIntent(intents.data ?? [], searchTerm), inferredIntents);
            return groupAndSortIntents(foundIntents);
        }, [searchVal, allIntentGroups, inferredIntents, selectedComment]);

        const onIntentSelect = useCallback(
            (intent: IPolicySet) => {
                props.onIntentSelect?.(intent, searchVal);
            },
            [searchVal, props.onIntentSelect],
        );
        if (intents.isLoading) {
            return <Typography color="gray">Loading...</Typography>;
        }

        return (
            <Root sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ p: 2, borderBottom: `1px solid ${theme.palette.divider}` }}>
                    <Typography variant="h5" fontWeight={500} sx={{ mb: 1 }}>
                        Intent Selection
                    </Typography>
                    <SearchAddIntent
                        disabled={props.disabled || loading}
                        setLoading={setLoading}
                        search={search}
                        onSearch={setSearch}
                        onAdd={() => setSearch('')}
                    />
                </Box>
                <IntentsList
                    disabled={props.disabled || loading}
                    expanded={Boolean(searchVal)}
                    intentsGrouped={intentGroups}
                    selectedIds={props.selectedIds}
                    onIntentClick={onIntentSelect}
                />
                {loading && <StyledLoader id="intents-styled-loader" />}
            </Root>
        );
    },
);

Intents.displayName = 'Intents';

const md = new MarkdownIt({
    breaks: false,
    linkify: true,
    typographer: true,
});

export const DemoIntents = memo((props: { onSelectIntent?: () => void; onNext?: () => void }) => {
    const theme = useTheme();
    const selectedComment = useSelectedComment();
    const [search, setSearch] = useState<string>('');
    const searchVal = useDebounce(search, 300) || '';
    const [busy, setBusy] = useState(false);
    const inferredIntents = selectedComment?.infer_data?.intents;
    const [intentGroups, setIntentGroups] = useState<IPolicySetGroup[]>();
    const { approve, submit, setIntent, intents, decision, isLoading, loadDecision } = useDemoTicket();
    const [approving, setApproving] = useState(false);
    const containerRef = useRef(null);
    const [explanations, setExplanations] = useState<IWorkflowExplanation[]>([]);
    const [isExplaining, setIsExplaining] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const allIntentGroups = useMemo(
        () => groupAndSortIntents(addProposedIntents(intents, inferredIntents)),
        [intents, inferredIntents],
    );

    useEffect(() => {
        setIntentGroups(allIntentGroups);
    }, [allIntentGroups]);

    useEffect(() => {
        if (!decision?.wf_explanations) return;

        setIsExplaining(true);
        setExplanations(decision.wf_explanations);
    }, [decision?.wf_explanations]);

    const onApprove = () => {
        setApproving(true);
        approve()
            .then(() => {
                setApproving(false);
                setIsExplaining(true);
                return loadDecision();
            })
            .finally(() => setIsSubmit(true));
    };

    const onSubmit = () => {
        setApproving(true);
        submit()
            .then(props.onNext)
            .catch(props.onNext)
            .finally(() => setApproving(false));
    };

    const onIntentClick = useCallback(
        async (intent: IPolicySet) => {
            if (selectedComment) {
                setBusy(true);
                await setIntent(intent.id);
                props.onSelectIntent?.();
                setBusy(false);
            }
        },
        [selectedComment],
    );

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    const isIntentSelected = Boolean(selectedComment?.selected_intent_id);
    return (
        <StyledIntentTitle title={!isIntentSelected && 'Intent Selection'}>
            <Root
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                ref={containerRef}
            >
                {!isIntentSelected && (
                    <SearchAddIntent
                        disabled={busy}
                        setLoading={setBusy}
                        search={search}
                        onSearch={setSearch}
                        onAdd={() => setSearch('')}
                    />
                )}
                {!isIntentSelected && (
                    <IntentsList
                        disabled={busy}
                        selectedIds={[]}
                        expanded={Boolean(searchVal)}
                        intentsGrouped={intentGroups}
                        onIntentClick={onIntentClick}
                    />
                )}
                {isIntentSelected && !decision && (
                    <LoadingButton
                        sx={{ m: 'auto' }}
                        size="large"
                        loading={approving}
                        variant="contained"
                        onClick={onApprove}
                    >
                        Approve
                    </LoadingButton>
                )}
                <Slide direction="up" in={Boolean(isIntentSelected && decision)} container={containerRef.current}>
                    <Box
                        sx={{
                            display: isIntentSelected && decision ? 'flex' : 'none',
                            justifyContent: 'space-around',
                            flexDirection: 'column',
                            width: '100%',
                            flex: 1,
                        }}
                    >
                        <Typography variant="h6" fontWeight={600}>
                            Extracted Information
                        </Typography>
                        <List>
                            <TransitionGroup>
                                {explanations.map((expl) => (
                                    <Collapse key={expl.condition_explanations[0]}>
                                        <ListItem sx={{ whiteSpace: 'wrap', p: 0, mb: 1, fontSize: '0.95em' }}>
                                            <Card
                                                sx={{
                                                    p: 1,
                                                    mr: 2,
                                                    border: `1px solid ${grey[200]}`,
                                                    minWidth: theme.spacing(12),
                                                    minHeight: theme.spacing(4),
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <CardMedia
                                                    component="img"
                                                    image={expl.wf_title}
                                                    alt="provider"
                                                    sx={{ width: theme.spacing(8) }}
                                                />
                                            </Card>
                                            <Box
                                                dangerouslySetInnerHTML={{
                                                    __html: sanitizeHtmlElements(
                                                        md.render(expl.condition_explanations[0] || ''),
                                                    ),
                                                }}
                                            />
                                        </ListItem>
                                    </Collapse>
                                ))}
                                {!isSubmit && isExplaining && (
                                    <Collapse>
                                        <ListItem sx={{ p: 0 }}>
                                            <Skeleton
                                                sx={{
                                                    minWidth: theme.spacing(30),
                                                    width: '100%',
                                                    height: theme.spacing(5),
                                                }}
                                            />
                                        </ListItem>
                                    </Collapse>
                                )}
                            </TransitionGroup>
                        </List>
                        <Slide direction="up" in={isSubmit} container={containerRef.current}>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="h6" fontWeight={600}>
                                    Actions
                                </Typography>
                                <List sx={{ mb: 2 }}>
                                    {decision?.actions.map((action) => (
                                        <ListItem
                                            key={action.title}
                                            sx={{ whiteSpace: 'wrap', p: 0, mb: 1, fontSize: '0.95em' }}
                                        >
                                            <Card
                                                sx={{
                                                    mr: 2,
                                                    border: `1px solid ${grey[200]}`,
                                                    minWidth: theme.spacing(12),
                                                    minHeight: theme.spacing(4),
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <CardMedia
                                                    component="img"
                                                    image={action.title}
                                                    alt={action.description}
                                                    sx={{ width: theme.spacing(8) }}
                                                />
                                            </Card>
                                            <Box
                                                dangerouslySetInnerHTML={{
                                                    __html: md.render(sanitizeHtmlElements(action.description)),
                                                }}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                                <LoadingButton
                                    loading={approving}
                                    variant="contained"
                                    onClick={onSubmit}
                                    size="large"
                                    sx={{ ml: 'auto' }}
                                >
                                    Submit
                                </LoadingButton>
                            </Box>
                        </Slide>
                    </Box>
                </Slide>
                {busy && <StyledLoader id="intents-styled-loader" />}
            </Root>
        </StyledIntentTitle>
    );
});

DemoIntents.displayName = 'DemoIntents';
