import axios from 'axios';
import { IGrPolicy } from '@tymely/atoms';

export type FetchGrIntentPolicyArgs = {
    orgPolicySetId: number;
    intentId: number;
};

export const fetchGrIntentPolicy = async ({ orgPolicySetId, intentId }: FetchGrIntentPolicyArgs) => {
    const params: Record<string, unknown> = {
        org_policy_set_id: orgPolicySetId,
        policy_set_id: intentId,
    };
    return await axios.get<IGrPolicy>('policies/search', { params }).then((res) => res.data);
};

export const upsertGrIntentPolicy = async (policy: IGrPolicy) =>
    await axios.put<IGrPolicy>(`policy/${policy.id}`, policy).then((res) => ({ ...policy, ...res.data }));
