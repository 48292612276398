import * as Diff from 'diff';
import { lazy, memo, useCallback, useEffect, useMemo, useState, ReactNode, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { AxiosError } from 'axios';
import { Box, Card, IconButton, Typography, FormControl, InputLabel, useTheme, Tooltip, Button } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import ForwardIcon from '@mui/icons-material/Forward';
import {
    useArgumentsMetadataQuery,
    useCreateArgumentMetadataMutation,
    useEditArgumentMetadataMutation,
    useRenderTemplateQuery,
    useTemplateFreeVariablesQuery,
    useSetAlert,
    useTicketOfflineQuery,
    useArgumentsQuery,
    useUser,
} from '@tymely/services';
import { IArgumentMetadata, IArgument, isCategorical } from '@tymely/atoms';
import { MultiSelect, Option, SearchInput, Loader } from '@tymely/components';
import { TemplateError, ITemplateVariable } from '@tymely/api';
import { Nullable } from '@global/types';
import { useDebounce } from '@tymely/utils';
import { Allotment } from 'allotment';

import { ArgMetadataEditDialog, defaultArgMetadata } from '../ArgMetadataEditor/ArgMetadataEditDialog';
import {
    BooleanArgument,
    InputFieldArgument,
    DateTimeArgument,
    MultiCategoryArgument,
    ArgumentFieldProps,
    ObjectArgument,
    ArgErrorFallback,
    AddressArgument,
} from '../Ticket/Arguments';
import RichTextEditor from '../Ticket/TicketLayout/RichTextEditor';

import 'allotment/dist/style.css';

const JinjaCodeEditor = lazy(() => import('../JinjaCodeEditor'));

const convertToArgument = (argMetadata: IArgumentMetadata, variable: ITemplateVariable) => {
    const argMetaCategories =
        Object.keys(argMetadata.options?.categories || {}).length > 0 ? argMetadata.options?.categories : null;

    const argument = {
        id: argMetadata.id,
        md_id: argMetadata.id,
        name: argMetadata.name,
        extractor_cls_name: argMetadata.extractor_name,
        title: argMetadata.title,
        description: argMetadata.description,
        is_edited: true,
        is_unspecified: argMetadata.unspecifiable ? variable.is_unspecified : false,
        is_list: argMetadata.is_list,
        unspecifiable: argMetadata.unspecifiable,
        neitherable: argMetadata.options?.neitherable ?? true,
        dtype: argMetadata.dtype,
        arg_type: argMetadata.arg_type,
        order: 1,
        value: variable.value,
        categories: variable.categories || argMetaCategories,
        created_at: new Date().toISOString(),
    };

    return argument as IArgument;
};
export const ArgEditor = memo(
    (props: { argument: IArgument; groupArg?: IArgument; onChange?: ArgumentFieldProps<IArgument>['onChange'] }) => {
        if (isCategorical(props.argument)) {
            const onChange = (args: (typeof props.argument)[]) =>
                props.onChange?.(args) as Promise<(typeof props.argument)[]>;
            return <MultiCategoryArgument {...props} onChange={onChange} withLabel argument={props.argument} />;
        }

        switch (props.argument.dtype) {
            case 'Image':
            case 'Url':
            case 'VideoUrl':
            case 'list':
            case 'int':
            case 'float':
            case 'str':
            case 'EmailStr':
            case 'list[str]':
            case 'list[Image]':
            case 'list[VideoUrl]':
            case 'list[Url]':
            case 'list[EmailStr]':
                return <InputFieldArgument {...props} withLabel argument={props.argument} />;
            case 'bool':
            case 'bool|None':
                return <BooleanArgument {...props} withLabel argument={props.argument} />;
            case 'datetime':
                return <DateTimeArgument {...props} withLabel argument={props.argument} />;
            case 'AddressStr':
                return <AddressArgument {...props} withLabel argument={props.argument} onChange={props.onChange} />;
            default:
                return <ObjectArgument {...props} withLabel argument={props.argument} onChange={props.onChange} />;
        }
    },
);

ArgEditor.displayName = 'ArgEditor';

const Variable = memo(
    (props: {
        name: string;
        value: ITemplateVariable;
        argMetadata?: IArgumentMetadata;
        groupArg?: IArgument;
        onChange: (varName: string, value: ITemplateVariable) => void;
    }) => {
        const [editOpen, setEditOpen] = useState(false);
        const editArgMetadata = useEditArgumentMetadataMutation();
        const createArgMetadata = useCreateArgumentMetadataMutation();
        const [argMetadata, setArgMetadata] = useState(
            props.argMetadata ?? {
                ...omit(defaultArgMetadata, 'id'),
                name: props.name,
                id: 0,
            },
        );

        const argument = useMemo(() => convertToArgument(argMetadata, props.value), [argMetadata, props.value]);

        const onSubmitArgMetadata = useCallback(
            (argMeta: IArgumentMetadata) => {
                const onSuccess = ({ id: argMetaId }: IArgumentMetadata) => {
                    if (argMetadata.dtype !== argMeta.dtype) {
                        props.onChange(argMeta.name, {
                            ...props.value,
                            is_unspecified: false,
                            is_neither: false,
                            value: '',
                        });
                    }
                    if (!isEqual(argMeta.options?.categories, argMetadata.options?.categories)) {
                        props.onChange(argMeta.name, {
                            ...props.value,
                            value: '',
                        });
                    }
                    if (argMetadata.options?.neitherable !== argMeta.options?.neitherable) {
                        props.onChange(argMeta.name, {
                            ...props.value,
                            value: '',
                            is_neither: false,
                        });
                    }
                    if (argMetadata.unspecifiable !== argMeta.unspecifiable) {
                        props.onChange(argMeta.name, {
                            ...props.value,
                            is_unspecified: false,
                        });
                    }
                    setArgMetadata({ ...argMeta, id: argMetaId });
                    setEditOpen(false);
                };

                if (argMetadata.id) {
                    return editArgMetadata.mutateAsync(argMeta).then(onSuccess);
                } else {
                    return createArgMetadata.mutateAsync({ metadata: argMeta }).then(onSuccess);
                }
            },
            [argMetadata, editArgMetadata, createArgMetadata, props.onChange],
        );

        const onChange = useCallback(
            async ([argument]: IArgument[]) => {
                props.onChange(argument.name, {
                    ...props.value,
                    value: argument.value,
                    is_unspecified: false,
                    is_neither: argument.value === null,
                });
                return [argument];
            },
            [props.value, props.onChange],
        );

        const onUnspecify = useCallback(() => {
            props.onChange(argument.name, {
                ...props.value,
                value: null,
                is_unspecified: true,
                is_neither: false,
            });
        }, [argument, props.value, props.onChange]);

        return (
            <>
                <Box display="flex" justifyContent="center" mb={2}>
                    <ErrorBoundary resetKeys={[argument]} FallbackComponent={ArgErrorFallback}>
                        <ArgEditor argument={argument} groupArg={props.groupArg} onChange={onChange} />
                    </ErrorBoundary>
                    <Tooltip title="Unspecified" enterDelay={0.5}>
                        <Box display="inline-flex">
                            <IconButton
                                aria-label="unspecified"
                                disabled={argument.is_unspecified || !argument.unspecifiable}
                                onClick={onUnspecify}
                            >
                                {argument.is_unspecified ? (
                                    <RadioButtonCheckedIcon fontSize="small" />
                                ) : (
                                    <RadioButtonUncheckedIcon fontSize="small" />
                                )}
                            </IconButton>
                        </Box>
                    </Tooltip>
                    <IconButton disabled={editArgMetadata.isLoading} onClick={() => setEditOpen(true)}>
                        {props.argMetadata ? <EditIcon fontSize="small" /> : <AddIcon fontSize="small" />}
                    </IconButton>
                </Box>
                {editOpen && (
                    <ArgMetadataEditDialog
                        argMetadata={argMetadata}
                        onSubmit={onSubmitArgMetadata}
                        onClose={() => setEditOpen(false)}
                    />
                )}
            </>
        );
    },
);

Variable.displayName = 'Variable';

const Variables = memo(
    (props: {
        template: string;
        variables: Record<string, ITemplateVariable>;
        onVariablesChange: (variables: Record<string, ITemplateVariable>) => void;
        onTemplateParse: (variables: Record<string, ITemplateVariable>) => void;
    }) => {
        const [argMetadata, setArgMetadata] = useState<Record<string, IArgumentMetadata>>();
        const argumentsMetadataQuery = useArgumentsMetadataQuery();

        const [metaVars, setMetaVars] = useState<Record<string, ITemplateVariable>>({});
        const template = useDebounce(props.template);
        const freeVariablesQuery = useTemplateFreeVariablesQuery(template);

        useEffect(() => {
            if (!argumentsMetadataQuery.data || !freeVariablesQuery.data) return;

            const argMetadata = argumentsMetadataQuery.data.reduce<Record<string, IArgumentMetadata>>(
                (acc, item) => ({ ...acc, [item.name]: item }),
                {},
            );
            const vars = freeVariablesQuery.data.reduce<Record<string, ITemplateVariable>>(
                (acc, varName) => ({
                    ...acc,
                    [varName]: {
                        value: '',
                        dtype: argMetadata[varName]?.dtype ?? 'str',
                        arg_type: argMetadata[varName]?.arg_type ?? 'VARIABLE',
                        is_list: argMetadata[varName]?.is_list ?? false,
                        is_neither: props.variables[varName]?.is_neither ?? false,
                        is_unspecified: props.variables[varName]?.is_unspecified ?? false,
                        group_by: props.variables[varName]?.group_by,
                    },
                }),
                {},
            );
            setArgMetadata(argMetadata);
            setMetaVars(vars);
            props.onTemplateParse(vars);
        }, [argumentsMetadataQuery.data, freeVariablesQuery.data, props.onTemplateParse]);

        const variables = useMemo(() => {
            return Object.keys(metaVars).reduce(
                (vars, varName) => {
                    vars[varName] = { ...metaVars[varName], ...props.variables[varName] };
                    return vars;
                },
                { ...metaVars },
            );
        }, [props.variables, metaVars]);

        const onVariableChange = useCallback(
            (varName: string, variable: ITemplateVariable) => {
                const vars = {
                    ...variables,
                    [varName]: { ...variables[varName], ...variable },
                };
                props.onVariablesChange(vars);
            },
            [variables, props.onVariablesChange],
        );

        if (Object.keys(props.variables).length === 0) {
            if (freeVariablesQuery.isError || argumentsMetadataQuery.isError) {
                return <Typography color="error">Error</Typography>;
            }

            if (freeVariablesQuery.isLoading || argumentsMetadataQuery.isLoading) {
                return <Typography>Loading...</Typography>;
            }

            return <Typography color="gray">No free variables detected in the template</Typography>;
        }

        return argMetadata ? (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {Object.keys(variables)
                    .sort((v1, v2) => v1.localeCompare(v2))
                    .map((variableName) => {
                        const groupByName = Object.keys(props.variables).find(
                            (k) => k === props.variables[variableName].group_by,
                        );
                        const groupArg = groupByName
                            ? convertToArgument(argMetadata[groupByName], props.variables[groupByName])
                            : undefined;
                        return (
                            <Variable
                                key={variableName}
                                name={variableName}
                                value={props.variables[variableName]}
                                argMetadata={argMetadata[variableName]}
                                groupArg={groupArg}
                                onChange={onVariableChange}
                            />
                        );
                    })}
            </LocalizationProvider>
        ) : (
            <Loader />
        );
    },
);

Variables.displayName = 'Variables';

export const TemplateEditor = memo(
    (props: {
        template?: string;
        diff?: Diff.Change[];
        sanitize?: boolean;
        showBackButton?: boolean;
        error?: TemplateError;
        children?: ReactNode;
        onChange?: (template: string, varNames: string[]) => void;
        onBack?: () => void;
        onClick?: () => void;
    }) => {
        const theme = useTheme();
        const showAlert = useSetAlert();
        const [variables, setVariables] = useState<Record<string, ITemplateVariable>>({});
        const [comment, setComment] = useState<Nullable<Option<number>[]>>([]);
        const [ticketId, setTicketId] = useState<number>();
        const user = useUser();

        useEffect(() => {
            const unloadCallback = (event: BeforeUnloadEvent) => {
                event.preventDefault();
                event.returnValue = '';
                return '';
            };

            window.addEventListener('beforeunload', unloadCallback);
            return () => window.removeEventListener('beforeunload', unloadCallback);
        }, []);

        const { data: ticket, isLoading: ticketLoading } = useTicketOfflineQuery(ticketId, {
            enabled: !!ticketId,
        });

        const { data: args, isLoading: argsLoading } = useArgumentsQuery({
            commentId: comment ? comment[0]?.value : undefined,
            argsVersion: undefined,
            onError: (err: AxiosError) => showAlert(err.message, 'error'),
        });

        const commentOptions = useMemo(() => {
            if (ticketLoading || argsLoading) return [];
            return (
                ticket?.comments
                    .filter((comm) => comm.is_customer)
                    .sort(
                        ({ inquiry_date: id1 }, { inquiry_date: id2 }) =>
                            new Date(id2).valueOf() - new Date(id1).valueOf(),
                    )
                    .map((comm) => {
                        const group = comm.selected_intent_id ? 'Tagged' : 'Untagged';
                        return {
                            name: comm.body ? comm.body.substring(0, 80) + '...' : comm.id.toString() + group,
                            value: comm.id,
                            group: { id: group, name: group },
                        };
                    }) || []
            );
        }, [ticket?.comments, ticketLoading, argsLoading]);

        useEffect(() => {
            const selectedOpt = commentOptions.find((opt) => opt.group.id === 'Tagged');
            if (selectedOpt) {
                setComment([selectedOpt]);
            }
        }, [commentOptions]);

        const argsVariables = useMemo(() => {
            if (!args) return variables;

            return args.reduce(
                (vars, arg) => {
                    vars[arg.name] = {
                        ...arg,
                        ...vars[arg.name],
                        categories: isCategorical(arg) ? arg.categories : undefined,
                        value: vars[arg.name]?.value || arg.value,
                    };
                    return vars;
                },
                { ...variables },
            );
        }, [variables, args]);

        const templateToRender = useDebounce(props.template, 300) || '';
        const { data: renderedTemplate, error: renderTemplateError } = useRenderTemplateQuery(
            templateToRender,
            argsVariables,
            props.sanitize,
            {
                retry: false,
                enabled: Boolean(templateToRender) && !argsLoading && !ticketLoading,
            },
        );

        const templateErrors = useMemo(() => {
            if (renderTemplateError instanceof TemplateError) {
                return renderTemplateError.detail;
            }
            if (props.error) {
                return props.error.detail;
            }
            return [];
        }, [renderTemplateError]);

        const onCodeChange = useCallback(
            (code: string) => {
                props.onChange?.(code, Object.keys(argsVariables));
            },
            [props.onChange, argsVariables],
        );

        useEffect(() => {
            if (props.template) {
                // Trigger onChange when variables fetched (after template changed)
                onCodeChange(props.template);
            }
        }, [argsVariables]);

        const { data } = useArgumentsMetadataQuery();
        const varNames = useMemo(
            () => (data?.map((item) => item.name) || []).concat(Object.keys(variables)),
            [data, variables],
        );

        const onTicketSearch = useCallback((ticketId: string) => {
            setTicketId(Number(ticketId));
        }, []);

        const onTemplateParse = useCallback((newVars: Record<string, ITemplateVariable>) => {
            setVariables((oldVars) => {
                return Object.keys(newVars).reduce((vars, varName) => {
                    if (oldVars[varName]) {
                        vars[varName] = { ...newVars[varName], value: oldVars[varName].value };
                    }
                    return vars;
                }, newVars);
            });
        }, []);

        if (!user?.isAdmin) {
            return <div>Access denied</div>;
        }

        return (
            <Allotment>
                <Allotment.Pane preferredSize="25%" minSize={300}>
                    <Card
                        sx={{
                            height: 1,
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                        }}
                    >
                        <Box height={1} display="flex" flexDirection="column">
                            <Typography variant="h5" mb={1}>
                                Variables
                            </Typography>
                            <Box mb={2} display="flex">
                                <SearchInput
                                    sx={ticketId ? { flex: 4, mr: 1 } : { flex: 1 }}
                                    input={{
                                        outlined: true,
                                        fullWidth: true,
                                        placeholder: 'Search Ticket…',
                                        sx: { '& input:focus': { minWidth: 90 } },
                                    }}
                                    clearOnSubmit={false}
                                    onSubmit={onTicketSearch}
                                />
                                {Boolean(ticketId) && (
                                    <FormControl size="small" sx={{ flex: 6 }}>
                                        <InputLabel shrink>Comment</InputLabel>
                                        <MultiSelect
                                            loading={ticketLoading || argsLoading}
                                            multiline={false}
                                            value={comment || []}
                                            label="Comment"
                                            options={commentOptions}
                                            grouped
                                            onOptsSelect={setComment}
                                        />
                                    </FormControl>
                                )}
                            </Box>
                            <Box flex={1} pt={1} overflow="auto">
                                <Variables
                                    variables={argsVariables}
                                    template={props.template || ''}
                                    onTemplateParse={onTemplateParse}
                                    onVariablesChange={setVariables}
                                />
                            </Box>
                        </Box>
                        {props.showBackButton && (
                            <Button
                                fullWidth
                                variant="contained"
                                startIcon={<ForwardIcon sx={{ transform: 'rotate(-180deg)' }} />}
                                sx={{ borderRadius: 0 }}
                                onClick={props.onBack}
                            >
                                Back
                            </Button>
                        )}
                    </Card>
                </Allotment.Pane>

                <Allotment.Pane preferredSize="75%">
                    <Allotment vertical>
                        <Allotment.Pane minSize={300}>
                            <Card
                                sx={{
                                    height: 1,
                                    border: '2px solid transparent',
                                    overflowY: 'auto',
                                    position: 'relative',
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                    ...(templateErrors.length ? { borderColor: theme.palette.error.light } : {}),
                                }}
                            >
                                <Suspense fallback={<Loader />}>
                                    <JinjaCodeEditor
                                        value={props.template || ''}
                                        diff={props.diff}
                                        height="100%"
                                        onChange={onCodeChange}
                                        errors={templateErrors}
                                        varNames={varNames}
                                    />
                                    {props.children}
                                </Suspense>
                            </Card>
                        </Allotment.Pane>
                        <Allotment.Pane snap={true}>
                            <Card
                                sx={{
                                    borderBottomLeftRadius: 0,
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    height: 1,
                                    p: 2,
                                    overflow: 'scroll',
                                }}
                            >
                                <RichTextEditor value={renderedTemplate || ''} />
                            </Card>
                        </Allotment.Pane>
                    </Allotment>
                </Allotment.Pane>
            </Allotment>
        );
    },
);
TemplateEditor.displayName = 'TemplateEditor';
