import React from 'react';
import { sanitizeHtml, shortTimeFormat } from '@tymely/utils';
import { IComment } from '@tymely/atoms';
import { Box, Divider, Link, Typography, ImageListItem, styled } from '@mui/material';
import root from 'react-shadow';
import MarkdownIt from 'markdown-it';

import { linkRegexp } from './utils';
import { BottomBar, CommentDate } from './styled';

const PhotoProvider = React.lazy(() =>
    import('react-photo-view').then((module) => ({ default: module.PhotoProvider })),
);

const PhotoView = React.lazy(() => import('react-photo-view').then((module) => ({ default: module.PhotoView })));

const _ImageListItem = styled(ImageListItem)`
    cursor: pointer;
`;

const _AttachmentsRoot = styled(Box)`
    padding: ${({ theme }) => theme.spacing(0)};
    margin-bottom: ${({ theme }) => theme.spacing(1)};
    gap: ${({ theme }) => theme.spacing(1)};
`;

const Attachments = React.memo((props: { attachments: string[] }) => {
    return (
        <_AttachmentsRoot component="ul" onClick={(e) => e.stopPropagation()}>
            <React.Suspense>
                <PhotoProvider>
                    {props.attachments.map((img) => (
                        <_ImageListItem key={img}>
                            <PhotoView src={img}>
                                <img src={img} loading="lazy" alt="attachment" />
                            </PhotoView>
                        </_ImageListItem>
                    ))}
                </PhotoProvider>
            </React.Suspense>
        </_AttachmentsRoot>
    );
});

Attachments.displayName = 'Attachments';

const _Link = styled(Link)`
    font-size: 0.9em;
    cursor: pointer;
    text-decoration: none;
`;

const _Divider = styled(Divider)`
    margin-top: ${({ theme }) => theme.spacing(2)};
    margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const _AttachmentsTitle = styled(Typography)`
    font-weight: bold;
    margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const md = new MarkdownIt({
    breaks: true,
    linkify: true,
    typographer: true,
});

type CommentBodyProps = { comment: IComment; subject?: string };

const CommentBody = React.memo(({ comment, subject }: CommentBodyProps) => {
    const html = comment.body_html || md.render(comment.body || '');
    const attachments = comment.attachment_links?.filter((link) => linkRegexp.test(link)) || [];
    const bodyHtml = React.useMemo(() => {
        return comment.is_customer ? sanitizeHtml(html) : html;
    }, [comment, html, attachments]);

    const [showAttachments, setShowAttachments] = React.useState(false);
    const toggleAttachments = React.useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setShowAttachments((show) => !show);
    }, []);

    return (
        <Box>
            <b>{comment.is_customer && subject}</b>
            <root.div>
                <div dangerouslySetInnerHTML={{ __html: bodyHtml }} />
            </root.div>
            {attachments.length > 0 && (
                <Box onClick={toggleAttachments}>
                    <_Divider />
                    <_AttachmentsTitle variant="subtitle2">
                        Attachments&nbsp;
                        <_Link>{showAttachments ? 'Hide' : 'Show'}</_Link>
                    </_AttachmentsTitle>
                    {showAttachments && <Attachments attachments={attachments} />}
                </Box>
            )}
            <BottomBar component="span">
                <CommentDate>{shortTimeFormat(comment.inquiry_date)}</CommentDate>
                {comment.is_public === false && <Typography variant="body2">Internal</Typography>}
            </BottomBar>
        </Box>
    );
});

CommentBody.displayName = 'CommentBody';

export default CommentBody;
