import React, {
    ReactNode,
    useCallback,
    useMemo,
    ElementType,
    useState,
    useRef,
    useEffect,
    forwardRef,
    ForwardedRef,
} from 'react';
import MenuItemBase from '@mui/base/MenuItem';
import Popper, { PopperProps } from '@mui/base/Popper';
import {
    MenuItemProps,
    Checkbox,
    styled,
    CheckboxProps,
    Box,
    menuItemClasses,
    CircularProgress,
    IconButton,
    useTheme,
    Collapse,
    ListItemButton,
    ListItemButtonProps,
    ListItemText,
} from '@mui/material';
import Input, { inputClasses } from '@mui/base/Input';
import SearchIcon from '@mui/icons-material/Search';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import groupBy from 'lodash/groupBy';

import CloseIcon from '../Icons/Close';
import { GroupedMenuOption, Menu, MenuOption } from './NavMenu';

export const StyledListItemButton = styled(ListItemButton)<ListItemButtonProps>(
    ({ theme, hidden }) => `
        list-style: none;
        padding: ${theme.spacing(1, 3)};
        padding-right: ${theme.spacing(1)};
        cursor: pointer;
        min-width: ${theme.spacing(29)};
        position: relative;
        outline: none;
        ${hidden ? 'display: none;' : 'display: flex;'}
        align-items: center;
        text-transform: capitalize;
        color: ${theme.nav.text};
        
        &.${menuItemClasses.focusVisible}, &:hover {
            color: ${theme.palette.common.white};
        }
    `,
);

export const StyledMenuItem = styled(MenuItemBase)<MenuItemProps>(
    ({ theme, hidden }) => `
        list-style: none;
        padding: ${theme.spacing(1, 5)};
        padding-right: ${theme.spacing(1)};
        cursor: pointer;
        min-width: ${theme.spacing(29)};
        position: relative;
        outline: none;
        ${hidden ? 'display: none;' : 'display: flex;'}
        align-items: center;
        text-transform: capitalize;

        &.${menuItemClasses.focusVisible}, &:hover {
            color: ${theme.palette.common.white};
        }
    `,
);

const StyledListbox = styled('ul')(
    ({ theme }) => `
    margin: 0;
    padding: 0;
    min-width: fit-content;
    overflow-y: auto;
    position: relative;
    border-color: inherit;
    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-track-piece {
        background: ${theme.palette.common.black};
        border-color: inherit;
        border-width: 1px 3px 1px 3px;
        border-style: solid;
    }
    ::-webkit-scrollbar-thumb {
        background: #66648E;
        border-radius: 6px;
    }
    `,
);

export const StyledPopper = styled((props: PopperProps) => <Popper {...props} placement="right-start" />)<{
    variant: 'primary' | 'nav';
    title: string;
}>(
    ({ theme, variant = 'primary', title }) => `
    display: flex;
    flex-direction: column;
    box-shadow: 8px 8px 100px rgba(113, 131, 211, 0.23);
    border-radius: ${theme.spacing(2)};
    background: ${theme.palette.common.white};
    border: 1px solid #F4F6FF;
    z-index: ${theme.zIndex.modal};
    max-height: 60vh;
    max-width: ${theme.spacing(50)};
    padding: ${theme.spacing(2, 0)};
    padding-right: ${theme.spacing(3)};
    ${
        title &&
        `
        &:before {
            content: '${title}';
            margin-bottom: ${theme.spacing(1)};
            margin-left: ${theme.spacing(3)};
            font-weight: 600;
        }    
    `
    }

    ${
        variant === 'nav' &&
        `
        border-radius: ${theme.shape.borderRadiusLarge}px;
        background: ${theme.nav.active};
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        color: ${theme.nav.text};
        border: 0;
        box-shadow: -7px 0px 19px 0px rgba(27, 26, 48, 0.20);
        border-color: ${theme.nav.active};
    `
    }
`,
);

const StyledCheckbox = styled((props: CheckboxProps) => <Checkbox {...props} color="primary" />)(
    ({ theme }) => `
    margin-right: ${theme.spacing(1.5)};
`,
);

export type SelectMenuProps<T, G = false> = {
    open: boolean;
    anchorEl: HTMLElement | null;
    multiple?: boolean;
    values: T[];
    options: (G extends false ? MenuOption<T> : GroupedMenuOption<T>)[];
    popper?: ElementType;
    variant?: 'primary' | 'nav';
    title?: string;
    filter?: boolean;
    filterPlaceholder?: string;
    onSelect: (values: T[]) => void;
    onClose: () => void;
    isLoading?: boolean;
    footer?: ReactNode;
    selectedShownFirst?: boolean;
};

const MenuItem = <T extends string | number>(props: {
    option: MenuOption<T | 'all'>;
    selected?: boolean;
    withCheckbox?: boolean;
    onSelect?: (value: T | 'all') => void;
    children: ReactNode;
    hidden?: boolean;
}) => {
    return (
        <StyledMenuItem
            hidden={props.hidden}
            selected={!props.withCheckbox && props.selected}
            onClick={() => props.onSelect?.(props.option.value)}
        >
            {/* <Background className="background" /> */}
            {props.withCheckbox && <StyledCheckbox checked={props.selected} />}
            <Box flex={1}>{props.children}</Box>
        </StyledMenuItem>
    );
};

const SearchMenuContainer = styled('div')`
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-color: inherit;
`;

const SearchInput = styled(Input)(
    ({ theme }) => `
    width: 100%;

    .${inputClasses.input} {
        width: 100%;
        background: ${theme.nav.active};
        outline: none;
        border: none;
        color: ${theme.palette.common.white};
        &::placeholder {
            color: #A7A9C1;
        }
    }
`,
);

type FilterMenuItemProps = {
    filter: string;
    setFilter: (filter: string) => void;
    placeholder?: string;
};

const FilterMenuItem = forwardRef((props: FilterMenuItemProps, ref: ForwardedRef<HTMLInputElement>) => {
    const theme = useTheme();

    return (
        <MenuItemBase slots={{ root: 'div' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    py: 1,
                    pl: 3,
                }}
            >
                <SearchIcon fontSize="small" />
                <SearchInput
                    sx={{ pl: 1 }}
                    slotProps={{
                        input: {
                            ref: ref,
                            onKeyDown: (e) => e.stopPropagation(),
                            onClick: (e) => e.stopPropagation(),
                        },
                    }}
                    placeholder={props.placeholder || 'Search…'}
                    value={props.filter}
                    onChange={(event) => props.setFilter(event.target.value)}
                />

                {props.filter.length > 0 && (
                    <Box position="absolute" right={theme.spacing(3)}>
                        <IconButton onClick={() => props.setFilter('')}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                )}
            </Box>
        </MenuItemBase>
    );
});

FilterMenuItem.displayName = 'FilterMenuItem';

export const SelectMenu = <T extends string | number>(
    props: SelectMenuProps<T> & {
        withAll?: boolean;
    },
) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const values = useMemo<Set<T>>(() => new Set(props.values), [props.values]);
    const allSelected = useMemo(() => values.size === props.options.length, [values, props.options]);
    const onSelect = useCallback(
        (value: T | 'all') => {
            if (value === 'all') {
                return props.onSelect(allSelected ? [] : props.options.map((opt) => opt.value));
            }

            if (values.has(value)) {
                values.delete(value);
            } else {
                values.add(value);
            }
            props.onSelect(props.multiple ? Array.from(values) : [value]);
        },
        [values, allSelected, props.options, props.multiple, props.onSelect],
    );

    const [optionsSorted, setOptionsSorted] = useState(props.options);

    useEffect(() => {
        if (!props.open) {
            const options = [...props.options].sort((a, b) => {
                // Selected first
                const selectedCmp = (values.has(b.value) ? 1 : 0) - (values.has(a.value) ? 1 : 0);

                // Then alphabetical
                if (selectedCmp === 0) {
                    return a.label.localeCompare(b.label);
                }
                return selectedCmp;
            });
            if (props.withAll && props.multiple) {
                options.unshift({ label: 'All', value: 'all' as T });
            }
            setOptionsSorted(options);
        }
    }, [props.options, props.multiple, props.withAll, props.open]);

    const [filter, setFilter] = useState('');

    useEffect(() => {
        if (props.open) {
            inputRef.current?.focus();
        }
    }, [props.open]);

    return (
        <Menu
            open={props.open}
            anchorEl={props.anchorEl}
            popper={props.popper || StyledPopper}
            popperProps={{ variant: props.variant, title: props.title }}
            container={SearchMenuContainer}
            onClose={props.onClose}
        >
            {props.filter && (
                <FilterMenuItem
                    ref={inputRef}
                    filter={filter}
                    setFilter={setFilter}
                    placeholder={props.filterPlaceholder}
                />
            )}
            <StyledListbox>
                {optionsSorted.map((opt) => (
                    <MenuItem
                        key={opt.value}
                        hidden={!!filter.trim() && !opt.label.toLowerCase().includes(filter.trim().toLowerCase())}
                        selected={opt.value === 'all' ? allSelected : values.has(opt.value)}
                        option={opt}
                        withCheckbox={props.multiple}
                        onSelect={onSelect}
                    >
                        {opt.view || opt.label}
                    </MenuItem>
                ))}
                {props.isLoading && !props.filterPlaceholder && (
                    <StyledMenuItem disabled={true}>
                        <Box>
                            <CircularProgress disableShrink color="secondary" />
                        </Box>
                    </StyledMenuItem>
                )}
            </StyledListbox>
            {props.footer}
        </Menu>
    );
};

export const MenuItemGroup = <T extends string | number>(
    props: Pick<SelectMenuProps<T, true>, 'options' | 'multiple' | 'onSelect' | 'values' | 'isLoading'> & {
        label: string;
        filter?: string;
    },
) => {
    const theme = useTheme();
    const values = useMemo<Set<T>>(() => new Set(props.values), [props.values]);

    const [open, setOpen] = useState(false);
    const toggleExpanded = useCallback(() => setOpen(!open), [setOpen, open]);

    const onSelect = useCallback(
        (value: T | 'all') => {
            let selection: T[];
            const allOptionsAreSelected = values.size && values.size === props.options.length;

            if (value === 'all') {
                selection = allOptionsAreSelected ? [] : props.options.map((opt) => opt.value);
            } else {
                if (values.has(value)) {
                    values.delete(value);
                } else {
                    values.add(value);
                }

                selection = props.multiple ? Array.from(values) : [value];
            }

            if (!allOptionsAreSelected && selection.length === props.options.length) {
                setOpen(true);
            }

            props.onSelect(selection);
            return selection;
        },
        [values, props.options, props.multiple, props.onSelect],
    );

    const isHidden = (opt: (typeof props.options)[number]) => {
        const filter = (props.filter ?? '').trim().toLowerCase();
        return (
            !!filter && !(opt.label.toLowerCase().includes(filter) || opt.group.label.toLowerCase().includes(filter))
        );
    };

    if (props.options.filter(isHidden).length === props.options.length) {
        return <></>;
    }

    return (
        <>
            <StyledListItemButton
                onClick={toggleExpanded}
                sx={{
                    fontSize: theme.typography.subtitle1,
                    lineHeight: 2,
                    backgroundColor: 'transparent',
                }}
            >
                {props.multiple && (
                    <StyledCheckbox
                        sx={{ p: 0 }}
                        indeterminate={values.size > 0 && values.size < props.options.length}
                        checked={values.size > 0 && values.size === props.options.length}
                        onClick={(e) => {
                            const selection = onSelect('all');
                            if (selection.length !== props.options.length) {
                                e.stopPropagation();
                            }
                        }}
                    />
                )}
                <ListItemText primary={props.label} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </StyledListItemButton>
            <Collapse in={open} unmountOnExit>
                {props.options.map((opt) => (
                    <MenuItem
                        key={opt.value}
                        hidden={isHidden(opt)}
                        selected={values.has(opt.value)}
                        option={opt}
                        withCheckbox={props.multiple}
                        onSelect={onSelect}
                    >
                        {opt.view || opt.label}
                    </MenuItem>
                ))}
            </Collapse>
        </>
    );
};

export const GroupedSelectMenu = <T extends string | number>(props: SelectMenuProps<T, true>) => {
    const inputRef = useRef<HTMLInputElement>(null);
    type Option = (typeof props.options)[number];
    const [groupedOptions, setGroupedOptions] = useState<[Option['group'], Option[]][]>([]);

    const [filter, setFilter] = useState('');

    const getSelectedValues = useCallback(
        (options: typeof props.options) => {
            return props.values.filter((value) =>
                options.map((opt) => opt.value).some((optValue) => optValue === value),
            );
        },
        [props.values],
    );

    const selectedValues = useMemo(() => getSelectedValues(props.options), [getSelectedValues, props.options]);
    const isOptionSelected = useCallback(
        (option: (typeof props.options)[number]) => {
            return selectedValues.includes(option.value);
        },
        [selectedValues],
    );

    const sortGroups = useCallback(() => {
        setGroupedOptions(
            Object.entries(groupBy(props.options, (opt) => opt.group.id))
                .sort(([k1, v1], [k2, v2]) => {
                    if (props.selectedShownFirst) {
                        if (v1.some(isOptionSelected) && !v2.some(isOptionSelected)) return -1;
                        if (!v1.some(isOptionSelected) && v2.some(isOptionSelected)) return 1;
                    }

                    const k1_is_trainee = k1.toLowerCase().includes('trainee');
                    const k2_is_trainee = k2.toLowerCase().includes('trainee');
                    if (k1_is_trainee && !k2_is_trainee) return 1;
                    if (k2_is_trainee && !k1_is_trainee) return -1;

                    return k1.localeCompare(k2);
                })
                .map(([, opts]) => [opts[0].group, opts]),
        );
    }, [props.options, props.selectedShownFirst, isOptionSelected]);

    useEffect(() => {
        if (!props.open) {
            sortGroups();
        }
    }, [props.options, props.selectedShownFirst, props.open]);

    useEffect(() => {
        if (props.open) {
            inputRef.current?.focus();
        }
    }, [props.open]);

    const onSelect = useCallback(
        (values: T[], allGroupOptions: T[]) => {
            props.onSelect(props.values.filter((value) => !allGroupOptions.includes(value)).concat(values));
        },
        [props.onSelect, props.values],
    );

    const onClose = useCallback(() => {
        setFilter('');
        props.onClose();
    }, [setFilter, props.onClose]);

    return (
        <Menu
            open={props.open}
            anchorEl={props.anchorEl}
            popper={props.popper || StyledPopper}
            popperProps={{ variant: props.variant, title: props.title }}
            container={SearchMenuContainer}
            onClose={onClose}
        >
            {props.filter && (
                <FilterMenuItem
                    ref={inputRef}
                    filter={filter}
                    setFilter={setFilter}
                    placeholder={props.filterPlaceholder || 'Type to filter...'}
                />
            )}
            <StyledListbox>
                {groupedOptions.map(([group, opts]) => (
                    <MenuItemGroup
                        key={group.id}
                        label={group.label}
                        options={opts}
                        filter={filter}
                        multiple={props.multiple}
                        onSelect={(values) =>
                            onSelect(
                                values,
                                opts.map((opt) => opt.value),
                            )
                        }
                        values={getSelectedValues(opts)}
                    />
                ))}
            </StyledListbox>
            {props.footer}
        </Menu>
    );
};
