import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography, useTheme } from '@mui/material';
import ReactFlow, { Edge, Handle, MarkerType, Node, NodeProps, Position } from 'reactflow';
import { IArgument, IArgumentMetadata } from '@tymely/atoms';
import {
    useArgumentsMetadataQuery,
    useArgumentsQuery,
    useEditArgumentMetadataMutation,
    useSelectedComment,
} from '@tymely/services';
import { BaseModal } from '@tymely/components';
import { graphStratify, layeringLongestPath, sugiyama } from 'd3-dag';
import React, { memo, Suspense, useCallback, useState } from 'react';
import { CodeEditor } from '@tymely/components/CodeEditor';
import CloseIcon from '@mui/icons-material/Close';

import { ArgMetadataEditDialog } from '../ArgMetadataEditor/ArgMetadataEditDialog';

const getLayout = (nodes: Node[], edges: Edge[]) => {
    const d3Nodes = nodes.map((node) => ({
        id: node.id,
        parentIds: edges.filter(({ target }) => target === node.id).map(({ source }) => source),
    }));
    const stratify = graphStratify();
    const dag = stratify(d3Nodes);

    const layout = sugiyama().layering(layeringLongestPath()).nodeSize([200, 100]).gap([50, 100]);
    layout(dag);

    const positionedNodes: Record<Node['id'], Node['position']> = [...dag.nodes()].reduce(
        (acc, node) => ({
            ...acc,
            [node.data.id]: { x: node.ux, y: node.uy },
        }),
        {},
    );

    return nodes.map((node) => ({ ...node, position: positionedNodes[node.id] }));
};

type ArgNodeProps = {
    argument: IArgument;
};

const buildGraph = (
    args: IArgument[],
): {
    nodes: Node<ArgNodeProps>[];
    edges: Edge[];
} => {
    return {
        nodes: args.map((arg) => ({
            id: arg.name,
            data: { argument: arg },
            position: { x: 0, y: 0 },
            type: 'argNode',
        })),
        edges: args
            .map((arg) =>
                arg.dependencies.map((depName) => ({
                    id: `${arg.name}-${depName}`,
                    source: arg.name,
                    target: depName,
                    markerEnd: { type: MarkerType.ArrowClosed },
                })),
            )
            .flat(),
    };
};

const ArgViewer = memo((props: { argument: ArgNodeProps['argument']; onClose: () => void }) => {
    const theme = useTheme();
    const [isTitleHover, setIsTitleHover] = useState(false);
    const [showArgEdit, setShowArgEdit] = useState(false);
    const argMetadataQuery = useArgumentsMetadataQuery();
    const editArgMetadataMutation = useEditArgumentMetadataMutation();
    const argValue = props.argument.value;

    const editArgument = useCallback(
        async (argumentMetadata: IArgumentMetadata) => {
            await editArgMetadataMutation.mutateAsync(argumentMetadata).then(() => {
                setShowArgEdit(false);
            });
        },
        [editArgMetadataMutation, setShowArgEdit],
    );

    return (
        <Dialog open={true} onClose={props.onClose} maxWidth="xl">
            <DialogTitle variant="h6">
                <Typography
                    variant="h6"
                    onClick={() => setShowArgEdit(true)}
                    onMouseEnter={() => setIsTitleHover(true)}
                    onMouseLeave={() => setIsTitleHover(false)}
                    color={isTitleHover ? theme.palette.primary.main : theme.palette.text.primary}
                    display="inline"
                    sx={{
                        pb: 0,
                        mr: 4,
                        cursor: isTitleHover ? 'pointer' : '',
                    }}
                >
                    {props.argument.title}
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={() => props.onClose()}
                    sx={{ p: 0, color: theme.palette.grey[500] }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Box width="100%" mb={1}>
                <Suspense>
                    <CodeEditor
                        code={typeof argValue == 'string' ? argValue : JSON.stringify(argValue)}
                        language="json"
                        autoFormat
                        editorProps={{ maxHeight: theme.spacing(50) }}
                    />
                </Suspense>
            </Box>
            {props.argument.confidence && (
                <DialogContent sx={{ pt: 0.8 }}>Confidence: {props.argument.confidence}</DialogContent>
            )}
            {showArgEdit && argMetadataQuery.data && (
                <ArgMetadataEditDialog
                    argMetadata={argMetadataQuery.data.filter((md) => md.id === props.argument.md_id)[0]}
                    onSubmit={editArgument}
                    onClose={() => setShowArgEdit(false)}
                />
            )}
        </Dialog>
    );
});

ArgViewer.displayName = 'ArgViewer';

const ArgNode = (props: NodeProps<ArgNodeProps>) => {
    const { argument } = props.data;
    const [showArgViewer, setShowArgViewer] = useState(false);

    return (
        <>
            <Box
                onClick={() => setShowArgViewer(true)}
                sx={{
                    border: '2px solid black',
                    borderRadius: '2px',
                    padding: '10px',
                    width: '200px',
                    textAlign: 'center',
                    background: ['TEXT_ARGUMENT', 'USER_INPUT'].includes(argument.arg_type) ? 'white' : 'lightblue',
                }}
            >
                <Handle
                    id="top"
                    type="target"
                    position={Position.Top}
                    style={{ background: 'transparent', border: 0 }}
                />
                {argument.title}
                <Handle
                    id="bottom"
                    type="source"
                    position={Position.Bottom}
                    style={{ background: 'transparent', border: 0 }}
                />
            </Box>
            {showArgViewer && <ArgViewer argument={argument} onClose={() => setShowArgViewer(false)} />}
        </>
    );
};

const nodeTypes = {
    argNode: ArgNode,
};

const ArgLinesageModal = (props: { open: boolean; onClose?: () => void }) => {
    const selectedComment = useSelectedComment();
    const argumentsQuery = useArgumentsQuery({
        commentId: selectedComment?.id,
        enabled: !!selectedComment?.selected_intent_id,
    });

    const graph = buildGraph(argumentsQuery.data ?? []);
    graph.nodes = getLayout(graph.nodes, graph.edges);

    return (
        <BaseModal {...props} closeLabel={null} maxWidth="xl" fullHeight>
            <Box display="flex" height={1}>
                {argumentsQuery.isLoading ? (
                    'Loading...'
                ) : (
                    <ReactFlow
                        defaultNodes={graph.nodes}
                        defaultEdges={graph.edges}
                        nodeTypes={nodeTypes}
                        fitView
                        nodesDraggable={false}
                        minZoom={0}
                    ></ReactFlow>
                )}
            </Box>
        </BaseModal>
    );
};

export default ArgLinesageModal;
