import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { fetchGrIntentPolicy, FetchGrIntentPolicyArgs, NonEmptyItemOverwrite, upsertGrIntentPolicy } from '@tymely/api';
import { IPolicySet, IOrganization, IGrPolicy } from '@tymely/atoms';
import { AxiosError } from 'axios';

import { useOrganizationQuery } from './organization.services';

const GR_INTENT_POLICY_QUERY_KEY = 'grIntentPolicy';

export type UseFetchGrPolicyQueryArgs = UseQueryOptions<IGrPolicy, AxiosError> & {
    orgId: IOrganization['id'];
    intentId: IPolicySet['id'];
};

export const useFetchGrIntentPolicyQuery = ({ orgId, intentId, ...options }: UseFetchGrPolicyQueryArgs) => {
    const organizationQuery = useOrganizationQuery(orgId);
    const orgPolicySetId = organizationQuery.data?.org_policy_set_id;

    const params: FetchGrIntentPolicyArgs = { orgPolicySetId: orgPolicySetId ?? 0, intentId };

    let enabled = Boolean(orgPolicySetId && intentId);
    if (typeof options.enabled === 'boolean') {
        enabled = enabled && options.enabled;
    }

    return useQuery<IGrPolicy, AxiosError>(
        [GR_INTENT_POLICY_QUERY_KEY, orgId, intentId],
        () => fetchGrIntentPolicy(params),
        { ...options, enabled },
    );
};

export const useUpsertGrIntentPolicyMutation = (props: {
    onSuccess?: (policy: IGrPolicy) => void;
    onError?: (error: string[]) => void;
}) => {
    const queryClient = useQueryClient();

    const { onSuccess } = props;
    return useMutation(
        [GR_INTENT_POLICY_QUERY_KEY],
        ({ policy }: { policy: IGrPolicy }) => {
            return upsertGrIntentPolicy(policy);
        },
        {
            onSuccess: async (data, { policy }) => {
                queryClient.setQueryData(
                    [GR_INTENT_POLICY_QUERY_KEY, policy.org_policy_set_id, policy.policy_set_id],
                    () => data,
                );
                return onSuccess?.({ ...policy, ...data });
            },
            onError: (error, { policy }) => {
                if (error instanceof NonEmptyItemOverwrite) {
                    props.onError?.([error.message]);
                } else {
                    props.onError?.([
                        `Failed saving policy for intent (id=${policy.id}, policy_set_id=${policy.policy_set_id} org_policy_set_id=${policy.org_policy_set_id}): ${
                            (error as any).message
                        }`,
                    ]);
                }
            },
        },
    );
};
