import { Box, IconButton, styled, Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { isEmpty } from 'lodash';
import { ICrumbAdditionalData, ITicketTrailEvent } from '@tymely/atoms';
import { EditAttributes, Error, ExpandLess, ExpandMore, Person, Rule, Search } from '@mui/icons-material';
import ReactJson from '@microlink/react-json-view';

import { formatBubbleDate } from './utils';

const IconTag = (props: { text: string; icon: React.ReactNode; title?: string }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.25 }} title={props.title}>
        <Box sx={{ height: 14, overflow: 'visible', display: 'flex', alignItems: 'center' }}>{props.icon}</Box>
        <span>{props.text}</span>
    </Box>
);

const EVENT_TAGS: ((data: ICrumbAdditionalData) => React.ReactNode)[] = [
    ({ username }) => (username ? <IconTag text={username} icon={<Person sx={{ fontSize: 15 }} />} /> : false),
    ({ policy_type }) => (policy_type ? `${policy_type}` : false),
    ({ lb_type }) => (lb_type ? `${lb_type}` : false),
    ({ logic_box_id }) => (logic_box_id ? `${logic_box_id}` : false),
    ({ argument_name }) =>
        argument_name ? (
            <IconTag text={argument_name} title="Argument name" icon={<EditAttributes sx={{ fontSize: 25 }} />} />
        ) : (
            false
        ),
    ({ search_term }) =>
        search_term ? (
            <IconTag text={search_term} title="Search term" icon={<Search sx={{ fontSize: 15 }} />} />
        ) : (
            false
        ),
];

const EVENT_DETAILS: ((data: ICrumbAdditionalData) => React.ReactNode)[] = [
    ({ decision }) =>
        decision?.title ? (
            <IconTag text={decision.title} title="Decision" icon={<Rule sx={{ fontSize: 15 }} />} />
        ) : (
            false
        ),
];

const _Root = styled(Typography)`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ddd;
`;

const _Header = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};
`;

const _Label = styled('strong', { shouldForwardProp: (prop: string) => !['isEmptyData'].includes(prop) })<{
    isEmptyData: boolean;
}>`
    ${({ isEmptyData }) => !isEmptyData && 'cursor: pointer;'};
`;

const _Error = styled(Error)`
    display: 'inline';
    font-size: 16;
`;

const _IconButton = styled(IconButton, { shouldForwardProp: (prop: string) => !['isEmptyData'].includes(prop) })<{
    isEmptyData: boolean;
}>`
    visibility: ${({ isEmptyData }) => (isEmptyData ? 'hidden' : 'visible')};
`;

const _Filler = styled(Box)`
    flex: 1;
`;

const _EventTags = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    gap: 1;
`;

const _Expanded = styled(Box)`
    margin: ${({ theme }) => theme.spacing(1, 0)};
`;

const _JsonContainer = styled(Box)`
    margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

type EventItemProps = TypographyProps & {
    event: ITicketTrailEvent;
};

const EventItem = ({ event, ...rest }: EventItemProps) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const isEmptyData = React.useMemo(() => isEmpty(event.additional_data), [event.additional_data]);
    const isBigData = React.useMemo(() => JSON.stringify(event.additional_data).length > 1000, [event.additional_data]);

    const details = React.useMemo(
        () => EVENT_DETAILS.map((item) => item(event.additional_data)).filter(Boolean),
        [event.additional_data],
    );

    return (
        <_Root variant="caption" color="GrayText" {...rest}>
            <_Header>
                {event.status !== 'SUCCESS' && <_Error color="warning" />}
                <_Label onClick={() => !isEmptyData && setIsExpanded((val) => !val)} isEmptyData={isEmptyData}>
                    {event.operation}
                </_Label>
                <_EventTags>
                    {EVENT_TAGS.map((tagFn, i) => {
                        const tag = tagFn(event.additional_data);
                        return tag ? <span key={i}>{tag}</span> : null;
                    })}
                </_EventTags>
                <_Filler />
                <Box>{formatBubbleDate(`${event.created_date}Z`, false, true)}</Box>
                <_IconButton isEmptyData={isEmptyData} onClick={() => !isEmptyData && setIsExpanded((val) => !val)}>
                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </_IconButton>
            </_Header>
            {isExpanded ? (
                <_JsonContainer>
                    <ReactJson
                        src={event.additional_data}
                        displayDataTypes={false}
                        collapsed={isBigData}
                        name={false}
                    />
                </_JsonContainer>
            ) : (
                !isEmpty(details) && (
                    <_Expanded>
                        {details.map((item, i) => (
                            <Box key={i}>{item}</Box>
                        ))}
                    </_Expanded>
                )
            )}
        </_Root>
    );
};

export default EventItem;
