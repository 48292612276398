import { memo, useCallback, useMemo, useState } from 'react';
import { Box, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/CheckBox';
import FailIcon from '@mui/icons-material/DisabledByDefaultRounded';
import {
    highlightSpecialWords,
    useAgentResponse,
    useDecisionQuery,
    useLogTicketProcess,
    useSelectedComment,
    useSetAlert,
    useTicketFinalizeSubmit,
    useTicketNav,
    useUpdateWorkflow,
} from '@tymely/services';
import { BaseModal, LinkPreview } from '@tymely/components';
import { IAction } from '@tymely/atoms';
import { afterTicketHandling } from '@tymely/api';

export const SubmitModal = memo((props: { open: boolean; onClose: () => void }) => {
    const theme = useTheme();
    const [linkEl, setLinkEl] = useState<Element | null>(null);
    const ticketFinalizeSubmit = useTicketFinalizeSubmit();
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [actionChecks, setActionChecks] = useState<Record<string, boolean>>({});
    const { logFailed, logProcessed } = useLogTicketProcess();
    const { changeStatus } = useUpdateWorkflow();
    const { agentResponse } = useAgentResponse();
    const [error, setError] = useState<string>();
    const setAlert = useSetAlert();
    const selectedComment = useSelectedComment();
    const { data: decision } = useDecisionQuery();
    const withActionChecks = decision?.status === 'BETA';
    const actions = decision?.actions || [];
    const { handleNext } = useTicketNav();

    const onSubmit = useCallback(() => {
        setSubmitting(true);
        const promise = ticketFinalizeSubmit(false, !withActionChecks)
            .then(() => setAlert('Ticket has been submitted', 'success'))
            .catch((result) => {
                setAlert(result.message, 'error');
            })
            .finally(() => {
                if (withActionChecks) {
                    setSubmitted(true);
                }
                setSubmitting(false);
            });

        if (!withActionChecks) {
            logProcessed('submitted');
            handleNext(promise);
        }
    }, [ticketFinalizeSubmit, withActionChecks, handleNext]);

    const onCheck = useCallback(
        (action: IAction, checked: boolean) => {
            setActionChecks((checks) => ({ ...checks, [action.id]: checked }));
        },
        [actionChecks],
    );

    const canProceed = useMemo(() => {
        return Object.keys(actionChecks).length === actions.length;
    }, [actionChecks, actions]);

    const onCheckActions = useCallback(() => {
        const failedActions = actions.filter((action) => actionChecks[action.id] === false);
        let promise = Promise.resolve() as Promise<any>;
        if (failedActions.length) {
            setSubmitting(true);
            logFailed(
                decision!.workflow_id,
                failedActions.map((action) => action.title),
            );
            promise = changeStatus.mutateAsync([decision!.workflow_id, 'TANDEM']);
        } else {
            logProcessed('submitted');
        }
        promise
            .then(() => {
                const waitFor = selectedComment
                    ? new Promise((resolve) => {
                          try {
                              afterTicketHandling(selectedComment.id);
                          } finally {
                              setTimeout(resolve, 100);
                          }
                      })
                    : undefined;
                return handleNext(waitFor);
            })
            .catch(() => {
                setError('Failed to update workflow status');
            })
            .finally(() => {
                setSubmitting(false);
            });
    }, [decision, actionChecks, handleNext]);

    const previewHtml = agentResponse ? highlightSpecialWords(agentResponse) : undefined;

    return (
        <BaseModal
            title={submitted ? 'Verify actions' : 'Submit response'}
            okLabel={submitted ? 'Go to next ticket' : 'Submit'}
            maxWidth="md"
            fullWidth
            loading={submitting}
            closable={!submitted}
            disabled={submitted ? !canProceed : false}
            open={props.open}
            onOk={submitted ? onCheckActions : onSubmit}
            onClose={props.onClose}
            scroll="paper"
        >
            {submitted ? (
                <Box display="flex" flexDirection="column">
                    <List sx={{ width: 'fit-content', margin: '0 auto' }}>
                        {actions.map((action) => (
                            <ListItemButton disableRipple key={action.id}>
                                <ListItemIcon
                                    sx={{
                                        minWidth: theme.spacing(3),
                                        alignSelf: 'flex-start',
                                    }}
                                    onClick={() => onCheck(action, true)}
                                >
                                    <IconButton
                                        sx={{
                                            color: actionChecks[action.id] === true ? 'success.main' : 'disabled',
                                        }}
                                    >
                                        <CheckIcon />
                                    </IconButton>
                                </ListItemIcon>
                                <ListItemIcon
                                    sx={{
                                        minWidth: theme.spacing(6),
                                        alignSelf: 'flex-start',
                                    }}
                                    onClick={() => onCheck(action, false)}
                                >
                                    <IconButton
                                        sx={{
                                            color: actionChecks[action.id] === false ? 'error.main' : 'disabled',
                                        }}
                                    >
                                        <FailIcon />
                                    </IconButton>
                                </ListItemIcon>
                                <ListItemText primary={action.title} secondary={action.description} />
                            </ListItemButton>
                        ))}
                    </List>
                    {error && (
                        <Typography variant="subtitle1" sx={{ color: 'error.main', marginTop: 'auto' }}>
                            {error}
                        </Typography>
                    )}
                </Box>
            ) : (
                <>
                    {linkEl && <LinkPreview anchorEl={linkEl} onClose={() => setLinkEl(null)} />}
                    {previewHtml && (
                        <Typography
                            paragraph
                            sx={{
                                whiteSpace: 'pre-line',
                                '& table': {
                                    borderCollapse: 'collapse',
                                },
                            }}
                            dangerouslySetInnerHTML={{
                                __html: previewHtml,
                            }}
                            onMouseOver={(event) => {
                                setLinkEl(event.target as Element);
                            }}
                            onClick={(event) => {
                                if (event.target instanceof HTMLAnchorElement) {
                                    window.open(event.target.href, '_blank');
                                    event.preventDefault();
                                }
                            }}
                        ></Typography>
                    )}
                </>
            )}
        </BaseModal>
    );
});

SubmitModal.displayName = 'SubmitModal';
