import React, { useState, useCallback, useEffect, useRef, forwardRef, ForwardedRef } from 'react';
import { Box, styled, BoxProps, Button, IconButtonProps, darken, useTheme, CircularProgress } from '@mui/material';
import { IconButton } from '@tymely/components';
import {
    useAgentResponse,
    useCreateTicketCrumb,
    useLogTicketProcess,
    useSetAlert,
    useTicket,
    useTicketActions,
    useTicketFinalizeSubmit,
    useTicketNav,
    useTicketOrganization,
} from '@tymely/services';
import SendIcon from '@tymely/components/Icons/Send';
import ReviewIcon from '@tymely/components/Icons/Review';
import ExclIcon from '@tymely/components/Icons/Excl';
import ResizeIcon from '@tymely/components/Icons/Resize';
import SaveIcon from '@tymely/components/Icons/Save';
import { Tooltip } from '@tymely/components/SideBar';
import { IDineshTicketOperations } from '@tymely/atoms';
import { enqueueTickets } from '@tymely/api';

import RichTextEditor from './RichTextEditor';
import { EscalationModal } from './EscalationModal';
import { ReportModal } from './ReportModal';
import { SubmitModal } from './SubmitDialog';

const ResizeButton = styled((props: IconButtonProps) => (
    <IconButton {...props} color={undefined}>
        <ResizeIcon />
    </IconButton>
))`
    position: absolute;
    width: 26px;
    height: 26px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid var(--ty-palette-divider);
    z-index: 1;
    color: #abafd7;
    &:hover {
        color: ${darken('#ABAFD7', 0.2)};
    }
`;

const _Container = forwardRef(
    (
        { animate, empty, ...props }: BoxProps & { empty: boolean; animate: boolean },
        ref: ForwardedRef<HTMLDivElement>,
    ) => <Box {...props} ref={ref} />,
);

_Container.displayName = '_Container';

const Container = styled(_Container)(
    ({ theme, empty: _, animate }) => `
    position: relative;
    height: min(max(var(--response-height, 0px), ${theme.spacing(18)}), 100%);    
    max-height: 66vh;
    padding-top: ${theme.spacing(2)};
    background-color: ${theme.palette.background.paper};
    border-top-left-radius: ${theme.spacing(4)};
    border-top-right-radius: ${theme.spacing(4)};
    ${animate && 'transition: height 0.2s ease-in-out'};
    border: 1px solid rgb(101 129 255 / 20%);
    filter: drop-shadow(-15px -15px 40px rgba(101, 129, 255, 0.20));
`,
);

const Scroller = styled(Box)(
    ({ theme }) => `
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: scroll;
    padding: ${theme.spacing(2, 3, 0, 3)};
    border-top-left-radius: ${theme.spacing(4)};
    border-top-right-radius: ${theme.spacing(4)};
    ::-webkit-scrollbar {
        width: 8px;
        height: 0;
    }
    ::-webkit-scrollbar-thumb:vertical {
        background: #66648E;
        border-radius: 6px;
    }
`,
);

const LoadingBackdrop = styled((props) => (
    <Box {...props}>
        <CircularProgress />
    </Box>
))(
    ({ theme }) => `
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff66;
    z-index:10;
    border-top-left-radius: ${theme.spacing(4)};
    border-top-right-radius: ${theme.spacing(4)};
`,
);

const TextareaWrapper = styled(
    ({
        isFetching,
        ...props
    }: {
        isFetching: boolean;
    } & BoxProps) => <Box {...props}>{props.children}</Box>,
)(
    (props) => `
    flex: 1;
    overflow-y: auto;
    width: 100%;
    mark {
        background: rgba(141, 254, 200, 0.46);
    }

    .DraftEditor-root .public-DraftEditorPlaceholder-inner::after {
        display: ${props.isFetching ? 'inline-block' : 'none'};
        overflow: hidden;
        vertical-align: bottom;
        -webkit-animation: ellipsis steps(12, end) 1s infinite;
        animation: ellipsis steps(12, end) 1s infinite;
        content: "\\2026\\2026\\2026\\2026";
        width: 0;
    }
    
    @keyframes ellipsis {
        to {
            width: 37px;
        }
    }
    
    @-webkit-keyframes ellipsis {
        to {
            width: 37px;
        }
    }
`,
);

const ButtonsWrapper = styled(Box)(
    ({ theme }) => `
    display: flex;
    justify-content: flex-end;
    background-color: #FBFBFB;
    position: sticky;
    bottom: 0;
    width: 100%;
    padding-bottom: ${theme.spacing(2.5)};
`,
);

export const Response = styled(
    (
        props: Omit<BoxProps, 'onSubmit'> & {
            highlights?: [number, number][];
            canSave?: boolean;
            canReview?: boolean;
            canSubmit?: boolean;
            onResize?: () => void;
        },
    ) => {
        const ticket = useTicket();
        const {
            config: { chat_enabled: chatEnabled },
        } = useTicketOrganization();
        const { agentResponse, isFetching, appMode, notSet, sendResponse, updateResponse } = useAgentResponse(true);
        const { cannotSubmitExplanation } = useTicketActions();
        const { logProcessed } = useLogTicketProcess();
        const { handleNext } = useTicketNav();
        const ticketFinalizeSubmit = useTicketFinalizeSubmit();
        const [isResponding, setIsResponding] = useState(false);
        const [responseText, setResponseText] = useState('');
        const [[startY, startH], setResize] = useState<[number?, number?]>([]);
        const refContainer = useRef<HTMLDivElement>(null);
        const refScroller = useRef<HTMLElement>(null);
        const theme = useTheme();
        const setAlert = useSetAlert();
        const createTicketCrumb = useCreateTicketCrumb();
        const [isEscalationModalOpen, setIsEscalationModalOpen] = useState(false);
        const [isReportModalOpen, setIsReportModalOpen] = useState(false);
        const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);

        useEffect(() => {
            setResponseText(agentResponse || '');
        }, [agentResponse]);

        const onBlur = useCallback(() => {
            updateResponse(responseText);
        }, [responseText]);

        const { canSave, canReview, canSubmit, ...rest } = props;

        const onResizeStart = useCallback((event: React.MouseEvent) => {
            const height = refContainer.current!.clientHeight;
            setResize([event.clientY, height]);
        }, []);

        const onResize = useCallback(
            (event: MouseEvent) => {
                if (startY === undefined || startH === undefined) return;
                refContainer.current!.style.setProperty('--response-height', startH + startY - event.clientY + 'px');
                props.onResize?.();
            },
            [startY, startH, props.onResize],
        );

        const onResizeStop = useCallback(() => {
            setResize([]);
        }, []);

        useEffect(() => {
            document.addEventListener('mousemove', onResize);
            document.addEventListener('mouseup', onResizeStop);
            return () => {
                document.removeEventListener('mouseup', onResizeStop);
                document.removeEventListener('mousemove', onResize);
            };
        }, [onResize, onResizeStop]);

        const onSubmit = useCallback(async () => {
            if (chatEnabled) {
                setIsResponding(true);
                handleNext(
                    sendResponse(responseText).then(() => {
                        logProcessed('submitted');
                        setResponseText('');
                    }),
                ).finally(() => setIsResponding(false));
            } else {
                setIsSubmitModalOpen(true);
            }
        }, [sendResponse, responseText, appMode, handleNext, logProcessed]);

        const onReview = useCallback(async () => {
            try {
                setIsResponding(true);
                createTicketCrumb(IDineshTicketOperations.USER_SENT_TICKET_FOR_REVIEW);

                await handleNext(
                    enqueueTickets([ticket.id], [{ organization_id: ticket.organization_id, channel: 'reviewer' }])
                        .then(() => {
                            logProcessed('sent to reviewer queue');
                            setAlert('Ticket was sent to reviewer queue', 'success');
                        })
                        .catch((error) => {
                            setAlert(`Failed to send ticket: ${(error as Error).message}`, 'error');
                        }),
                );
            } finally {
                setIsResponding(false);
            }
        }, [ticket, setAlert, logProcessed, handleNext]);

        const onSave = useCallback(async () => {
            try {
                setIsResponding(true);
                createTicketCrumb(IDineshTicketOperations.USER_SAVED_TICKET);

                await handleNext(
                    ticketFinalizeSubmit(true).then(() => {
                        logProcessed('saved');
                        setAlert('Ticket has been saved', 'success');
                    }),
                );
            } finally {
                setIsResponding(false);
            }
        }, [logProcessed, createTicketCrumb, ticketFinalizeSubmit, handleNext, setAlert]);

        const [isResponsiveHeight, setIsResponsiveHeight] = useState(false);
        const [isFocused, setIsFocused] = useState(false);

        useEffect(() => {
            setTimeout(() => {
                if (refContainer.current && responseText) {
                    if (isResponsiveHeight) {
                        const contentHeight = refContainer.current.querySelector('.DraftEditor-root')!.clientHeight;
                        const height = contentHeight + 24 /* one more line */ + 62; /* buttons row */
                        refContainer.current.style.setProperty(
                            '--response-height',
                            `calc(${height}px + ${theme.spacing(4)})`,
                        );
                    } else {
                        refContainer.current.style.removeProperty('--response-height');
                    }
                    props.onResize?.();
                }
            }, 100);
        }, [responseText, isResponsiveHeight, props.onResize]);

        return (
            <>
                <Container
                    {...rest}
                    ref={refContainer}
                    empty={notSet}
                    animate={!startY}
                    onFocus={() => {
                        setIsResponsiveHeight(true);
                        setIsFocused(true);
                    }}
                    onBlur={() => {
                        setIsFocused(false);
                        setIsResponsiveHeight(false);
                    }}
                    onMouseOver={() => setIsResponsiveHeight(true)}
                    onMouseOut={() => {
                        if (!isFocused) {
                            setIsResponsiveHeight(false);
                        }
                    }}
                >
                    {!isFetching && !isResponding && <ResizeButton onMouseDown={onResizeStart} />}
                    <Scroller ref={refScroller}>
                        {isResponding && <LoadingBackdrop />}
                        <TextareaWrapper isFetching={isFetching}>
                            <RichTextEditor
                                value={responseText}
                                onChange={setResponseText}
                                onBlur={onBlur}
                                placeholder="Response"
                                spellCheck
                            />
                        </TextareaWrapper>
                        <ButtonsWrapper>
                            <Box
                                sx={{
                                    display: 'flex',
                                    left: theme.spacing(-1),
                                    bottom: theme.spacing(1),
                                    position: 'absolute',
                                }}
                            >
                                {chatEnabled ? (
                                    <Button
                                        variant="text"
                                        color="error"
                                        sx={{ mr: 1 }}
                                        onClick={() => setIsEscalationModalOpen(true)}
                                    >
                                        <ExclIcon />
                                        <Box ml={0.5}>Escalate</Box>
                                    </Button>
                                ) : (
                                    <Button
                                        variant="text"
                                        color="error"
                                        sx={{ mr: 1 }}
                                        onClick={() => setIsReportModalOpen(true)}
                                    >
                                        <ExclIcon />
                                        <Box ml={0.5}>Report</Box>
                                    </Button>
                                )}
                            </Box>
                            <Box>
                                <Tooltip placement="top" title="Save">
                                    <Box display="inline-block" sx={{ mr: 1 }}>
                                        <IconButton
                                            disabled={!canSave}
                                            color="secondary"
                                            onClick={onSave}
                                            aria-label="Save"
                                        >
                                            <SaveIcon />
                                        </IconButton>
                                    </Box>
                                </Tooltip>
                                <Tooltip placement="top" title="Review">
                                    <Box display="inline-block" sx={{ mr: 1 }}>
                                        <IconButton
                                            disabled={!canReview}
                                            color="info"
                                            onClick={onReview}
                                            aria-label="Review"
                                        >
                                            <ReviewIcon />
                                        </IconButton>
                                    </Box>
                                </Tooltip>
                                <Tooltip placement="top" title={cannotSubmitExplanation || 'Submit'}>
                                    <Box display="inline-block">
                                        <IconButton
                                            disabled={!canSubmit}
                                            color="primary"
                                            onClick={onSubmit}
                                            aria-label="Submit"
                                        >
                                            <SendIcon />
                                        </IconButton>
                                    </Box>
                                </Tooltip>
                            </Box>
                        </ButtonsWrapper>
                    </Scroller>
                </Container>
                {!chatEnabled && (
                    <SubmitModal
                        open={isSubmitModalOpen}
                        onClose={() => {
                            setIsSubmitModalOpen(false);
                        }}
                    />
                )}
                {chatEnabled ? (
                    <EscalationModal
                        open={isEscalationModalOpen}
                        escalateResponse={agentResponse || 'One moment please…'}
                        onClose={() => setIsEscalationModalOpen(false)}
                    />
                ) : (
                    <ReportModal open={isReportModalOpen} onClose={() => setIsReportModalOpen(false)} />
                )}
            </>
        );
    },
)``;
